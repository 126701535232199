
import { MY_PROFILE } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getMyProfile = () => (dispatch) => {
    return axios({
        method: "GET",
        headers: { Authorization: `Bearer ${userInfo?.token}` },
        url: `${BASEURL}social/ig/getmyprofile`,
        // params: {
        //     page,
        //     limit
        // },
        // data: {

        // }
    })
        .then((response) => {
            dispatch({
                type: MY_PROFILE,
                payload: response.data,
            });
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
