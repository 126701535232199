import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import placeholder from '../../images/placeholder.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  getAllCategories,
  getParentCategories,
  postSaveCategories,
  verifyCategories,
} from '../../redux/actions/category.action';
import { useDispatch } from 'react-redux';
import '../../css/category.scss';
import { notification } from 'antd';
import EditCustomCategory from './editCategory';

function CategorySetup() {
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(false);
  const [categoryError, setCategoryError] = useState('');
  const [categoryLimit, setCategoryLimit] = useState('');
  const [myCategory, setMyCategory] = useState('');
  const [saveCategories, setSaveCategories] = useState('');

  useEffect(() => {
    dispatch(getAllCategories()).then((res) => {
      if (res.type == 'GET_ALL_CATEGORIES_SUCCESS') {
        const selectCategories = [];
        const myCategories = res.payload.message;
        myCategories.map(({ parent_id, category_name, image_url }) => {
          return selectCategories.push({
            value: parent_id,
            label: category_name,
            image: image_url,
          });
        });
        setMyCategory(selectCategories);
        setCategoryLimit(res.payload.category_limit);
      } else {
      }
    });
    dispatch(getParentCategories()).then((res) => {
      if (res.type == 'GET_PARENT_CATEGORIES_SUCCESS') {
        const saveCategories = [];
        const optionCategories = res.payload.message;
        optionCategories.map(
          ({ parent_id, category_name, image_url, editable, category_id }) => {
            return saveCategories.push({
              value: parent_id,
              label: category_name,
              image: image_url,
              editable: editable,
              category_id: category_id,
            });
          }
        );
        setSaveCategories(saveCategories);
      } else {
      }
    });
  }, []);

  const SortableItem = SortableElement(({ value }) => (
    <div key={value.value} className="cat-box col-sm-3 col-4">
      <img
        key={value.value}
        src={
          value.image === '' || value.image === undefined
            ? placeholder
            : value.image
        }
        alt="cat-logo"
        className="img-fluid cat-image"
      />
      <div className="cat-lable">{value.label}</div>
      {value.editable ? (
        <div className="action">
          <EditCustomCategory
            userID={userInfo.user_id}
            fetchMyCategory={() =>
              dispatch(getAllCategories()).then((res) => {
                if (res.type == 'GET_ALL_CATEGORIES_SUCCESS') {
                  const selectCategories = [];
                  const myCategories = res.payload.message;
                  myCategories.map(
                    ({ parent_id, category_name, image_url }) => {
                      return selectCategories.push({
                        value: parent_id,
                        label: category_name,
                        image: image_url,
                      });
                    }
                  );
                  setMyCategory(selectCategories);
                  setCategoryLimit(res.payload.category_limit);
                } else {
                }
              })
            }
            fetchSaveCategory={() =>
              dispatch(getParentCategories()).then((res) => {
                if (res.type == 'GET_PARENT_CATEGORIES_SUCCESS') {
                  const saveCategories = [];
                  const optionCategories = res.payload.message;
                  optionCategories.map(
                    ({
                      parent_id,
                      category_name,
                      image_url,
                      editable,
                      category_id,
                    }) => {
                      return saveCategories.push({
                        value: parent_id,
                        label: category_name,
                        image: image_url,
                        editable: editable,
                        category_id: category_id,
                      });
                    }
                  );
                  setSaveCategories(saveCategories);
                } else {
                }
              })
            }
            catData={value}
          />
        </div>
      ) : null}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <Row>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index.toString()}`}
          index={index}
          value={value}
        />
      ))}
    </Row>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSaveCategories(arrayMove(saveCategories, oldIndex, newIndex));
    setSort(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let category =
      saveCategories === null
        ? []
        : saveCategories.map((category) => {
            return {
              category_name: category.label,
              category_id: category.editable
                ? category.category_id
                : category.value,
              image_url: category.image,
              editable: category.editable,
            };
          });
    setLoading(true);
    dispatch(postSaveCategories(category, sort)).then((res) => {
      if (res.type == 'POST_SAVE_CATEGORIES_SUCCESS') {
        setCategoryError('');
        setLoading(false);
        setSort(false);
        notification.success({
          message: res.payload.message,
          className: 'toast-success',
        });
        dispatch(getParentCategories()).then((res) => {
          if (res.type == 'GET_PARENT_CATEGORIES_SUCCESS') {
            const saveCategories = [];
            const optionCategories = res.payload.message;
            optionCategories.map(
              ({
                parent_id,
                category_name,
                image_url,
                editable,
                category_id,
              }) => {
                return saveCategories.push({
                  value: parent_id,
                  label: category_name,
                  image: image_url,
                  editable: editable,
                  category_id: category_id,
                });
              }
            );
            setSaveCategories(saveCategories);
          } else {
          }
        });
      } else {
        notification.error({
          message: res.payload.data.message,
          className: 'toast-error',
        });
        setCategoryError('');
        setLoading(false);
      }
    });
  };

  const handleSelect = (e, options) => {
    if (e.removedValue) {
      if (e.removedValue.category_id) {
        dispatch(verifyCategories(e.removedValue.category_id)).then((res) => {
          if (res.payload.success) {
            Swal.fire({
              title: 'Are You Sure You Want To Delete This Category?',
              text: 'This Will Uncategorize All Your Post Related To This Category.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#000080',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
              if (result.isConfirmed) {
                setSaveCategories(options);
                // dispatch(verifyCategories(e.removedValue.category_id)).then((res) => {
                //   if (res.payload.success) {
                //     setSaveCategories(options);
                //   } else {
                //     notification.error({
                //       message: res.payload.data.message,
                //       className: 'toast-error',
                //     });
                //   }
                // });
              } else {
                setSaveCategories(saveCategories);
              }
            });
          } else {
            notification.error({
              message: res.payload.data.message,
              className: 'toast-error',
              duration: 6,
            });
          }
        });
      } else {
        options = options === null ? [] : options;
        setSaveCategories(options === null ? [] : options);
        setCategoryError('');
      }
    } else {
      options = options === null ? [] : options;
      setSaveCategories(options === null ? [] : options);
      setCategoryError('');
    }
  };

  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">Category Setup</h1>
        </div>
        <div className="brand_container_main container">
          <>
            <Row>
              <div className="profile_box_main col-md-8">
                <div className="brand-section dash_block_profile">
                  <div className="dash_content_profile">
                    {/* <p
                      style={{
                        color: 'gray',
                        borderBottom: '1px solid lightgray',
                        paddingBottom: 10,
                      }}
                    >
                      {/* Number Of Categories In {userInfo.package.package_name}{' '}
                      Plan Is {userInfo.package.category_count} 
                    </p> */}
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <label>Add Category</label>
                          <p>({saveCategories.length})</p>
                        </div>

                        {saveCategories === '' ? null : (
                          <Select
                            isMulti={true}
                            name="category"
                            className="selectCustomization"
                            options={myCategory}
                            value={saveCategories}
                            placeholder="Select Category"
                            onChange={(options, e) => handleSelect(e, options)}
                            isClearable={false}
                          />
                        )}
                        <span className="text-danger">{categoryError}</span>

                        {saveCategories.length === 0 ? (
                          <Row>
                            <span className="ml-4 mt-2 mb-2">
                              No Category Selected
                            </span>
                          </Row>
                        ) : (
                          <SortableList
                            items={saveCategories}
                            onSortEnd={onSortEnd}
                            axis="xy"
                            lockToContainerEdges={true}
                            lockOffset="10%"
                            distance={1}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={5} xl={3} className="mt-4">
                        <button
                          // variant="primary"
                          // type="submit"
                          className="btn category-btn btn-block default-btn fw-normal"
                          id="cat-save"
                          disabled={!loading ? false : true}
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* <div className="profile_box_main col-md-4">
                <div className="brand-section dash_block_profile">
                  <div className="dash_content_profile">
                    <p
                      style={{
                        color: 'gray',
                        borderBottom: '1px solid lightgray',
                        paddingBottom: 10,
                      }}
                    >
                      Buy Additional Categories
                    </p>
                  </div>
                </div>
              </div> */}
            </Row>
          </>
        </div>
      </div>
    </>
  );
}
export default CategorySetup;
