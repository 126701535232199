import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Steps, message, Select, InputNumber, notification } from "antd";
import { Spin } from "antd";
import { useStepsForm } from "sunflower-antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getCountry } from "../../redux/actions/country.action.js";
import { getState } from "../../redux/actions/state.action";
import { getCity } from "../../redux/actions/city.action";
import "../../css/login.scss";
import "../../css/public/register.scss";

import logo from "../../images/ormelogo.svg";
import { getBrandRegister } from "../../redux/actions/login.action";

const { Step } = Steps;
const { Option } = Select;

const steps = [
  {
    title: '',
    content: 'First-content',
  },
  {
    title: '',
    content: 'Second-content',
  },
  {
    title: '',
    content: 'Last-content',
  },
];

export default function Register() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState();
  const [country, setCountry] = useState("US");
  const [spinner, setSpin] = useState(false);
  const { login } = useSelector((state) => {
    return state;
  });


  useEffect(() => {
    dispatch(getCountry()).then((res) => {
      const selectCountries = [];
      res.payload.message.map(({ name, code1 }) => {
        return selectCountries.push({ value: code1, label: name });
      });
      setCountryList(selectCountries);
      dispatch(getState(country)).then((res) => {
        const selectStates = [];
        res.payload.message.map(({ name, isoCode }) => {
          return selectStates.push({ value: isoCode, label: name });
        });
        setStateList(selectStates);
      });
    });
  }, []);


  // const [error, setError] = useState("");
  // const onFinish = (values) => {
  //   setSpin(true);
  //   dispatch(postLogin(values)).then((res) => {
  //     if (res.payload.success) {
  //       localStorage.setItem("userInfo", JSON.stringify(res?.payload?.message));
  //       history("/events");
  //       window.location.reload();

  //       setSpin(false);
  //       // window.location.reload();
  //     } else {
  //       setError("User does not exist");
  //       setSpin(false);
  //     }
  //   });
  // };
  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };


  const changeCountry = (value) => {
    setCountry(value);
    dispatch(getState(value)).then((res) => {
      const selectStates = [];
      res.payload.message.map(({ name, isoCode }) => {
        return selectStates.push({ value: isoCode, label: name });
      });
      setStateList(selectStates);
      setCityList();
      form.resetFields(["state"]);
      form.resetFields(["city"]);
    });
  };

  const changeState = (value) => {
    dispatch(getCity(country, value)).then((res) => {
      const selectStates = [];
      res.payload.message.map(({ name }) => {
        return selectStates.push({ value: name, label: name });
      });
      setCityList(selectStates);
    });
  };



  const { form, initialValues, gotoStep, stepsProps, formProps, submit, formLoading } = useStepsForm({
    async submit(values) {
      console.log(values, "ss");
      	dispatch(getBrandRegister(values)).then((res) => {
      		if (res.payload.success) {
      		    history('/login')
              notification.success({
                message: "Account Created Successfully!",
                className: "toast-success",
              });
      		} else {
            notification.error({
              message: "Sign Up Failed!",
              className: "toast-error",
            });
      		}
      	});
      }

    });
  

  const [current, setCurrent] = useState(0);
  const next = async () => {
    await form.validateFields(['name', 'email', 'country', 'state', 'password','confirm']);

    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <div className="login-main">
        <div className="login-left">
          <div className="login-left-content">
            <div className="intro-main">
              <h1>Introducing</h1>
              <h2>LIVE SHOPPING</h2>
              <p>
                Boost your sales, create authentic and engaging relationships
                with your customers, in an innovative way!
              </p>
            </div>
          </div>
        </div>
        <div className="login-right">
          <div className="login-right-content form-styling">
            <div className="logo-login">
              <Link to="/"> <img src={logo} alt="logo" />
              </Link>
            </div>
            <h2 className="login-hd">Register</h2>


            <>
              <Steps className="register-steps" {...stepsProps} current={current} responsive={false}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div>&nbsp;</div>
              <h4>Create an Account</h4>
              <Form {...formProps}
                 
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                 
                {current == "0 " && (
                  <>

                    <Form.Item
                      label="Company Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Company Name",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Company Name" />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: "Please input your Email!",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>



                  </>
                )
                }

                {current == "1" && (
                  <>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Country",
                        },
                      ]}
                    >
                      <Select showSearch placeholder="Select Country" size="large" optionFilterProp="children" onChange={changeCountry} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {countryList.map((item) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter State",
                        },
                      ]}
                    >
                      <Select showSearch placeholder="Select State" optionFilterProp="children" size="large" onChange={changeState} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {stateList.map((item) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {cityList && (
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: cityList.length ? true : false,
                            message: "Please Enter City",
                          },
                        ]}
                      >
                        <Select showSearch placeholder="Select City" size="large" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                          {cityList.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </>

                )
                }

                {current == "2 " && (
                  <>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password size="large" placeholder="password" />
                    </Form.Item>

                    <Form.Item
                      label="Confirm Password"
                      name="confirm"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Confirm password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("The two passwords that you entered do not match!"));
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" placeholder="password" />
                    </Form.Item>


                    <Form.Item name="zipCode">
                      <Input type="number" placeholder={`Zip code`} size="large" min={5} />
                    </Form.Item>
                    <Form.Item name="refferedBy">
                      <Input placeholder={`Referred By`} size="large" />
                    </Form.Item>
                  </>
                )
                }
              </Form>

              <div className="steps-action">
<div className={` d-flex  ${current == 0 ? "justify-content-end" : "justify-content-between"} `}>

{current > 0 && (
                  <Button
                  className="default-btn mediu-btn mb-10 m-0"
                    // style={{
                    //   margin: '0 8px',
                    // }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
{current < steps.length - 1 && (
                  <Button type="primary"
                  className="default-btn mediu-btn mb-10 m-0 justify-self-end"
                  onClick={() => next()}>
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary"
                  className="default-btn mediu-btn mb-10 m-0"
                  onClick={() => submit()}>
                    Register
                  </Button>
                )}

</div>



              </div>
            </>



            Already have an account?  <Link className="signin-link" to="/login">
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

