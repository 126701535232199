import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
//import { toast } from "react-toastify";
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import Box from './Box/box';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
//import ReactPaginate from "react-paginate";
import { useDispatch } from 'react-redux';
import { DatePicker, Spin, Form } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as hashActions from '../../../redux/actions/hashtags';
import axios from 'axios';
import { BASEURL } from '../../../config';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

function TopHashTag({
  getMarketPlace,
  marketPlace,
  addCampaignToShop,
  getUserCategories,
  getBrandsCategory,
  type,
  title,
  getBrands,
  brands,
  getHashtag,
  hashtags,
  hashtag,
  clearHashtag,
  createHashtag,
  deleteHash
}) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [searchLoading, setSearchLoading] = useState(true);
  const [clearLoading, setClearLoading] = useState(false);
  const [spinner, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const [category, setCategory] = useState({ value: 'all', label: 'ALL' });
  const [categoryOptions, setCategoryOptions] = useState([]);
  // const [brand, setBrand] = useState({ value: "all", label: "ALL" });
  const [brand, setBrand] = useState('');

  const [sortBy, setSortBy] = useState({
    value: 'date',
    label: 'DATE',
  });
  const [orderBy, setOrderBy] = useState({ value: 'desc', label: 'DESC' });
  const [currentPage, setCurrentPage] = useState(0);

  // const fromDate = moment().subtract(2, "year").format("YYYY-MM-DD");
  // const fromDate = moment()
  //   .subtract(2, "year")
  //   .startOf("year")
  //   .format("YYYY-MM-DD");
  const fromDate = moment().startOf('year').format('YYYY-MM-DD');

  const toDate = moment(new Date()).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const limit = 8;

  //   useEffect(() => {
  //     setLoading(true);
  //     getMarketPlace(
  //       1,
  //       limit,
  //       "all",
  //       "commission",
  //       "desc",
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(function () {
  //       setLoading(false);
  //     });
  //     getBrands();
  //     return () => {};
  //   }, []);

  useEffect(() => {

    const config = {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    };

    axios
      .post(`${BASEURL}graph/hash/own`, {}, config)

      .then((res) => {
        console.log(res);
        setSearchLoading(true);
        setBrand(res.data.message.hashtag_id);
        getHashtag(
          {
            hashtag_id: res.data.message.hashtag_id,
            from_date: startDate.toString(),
            to_date: endDate.toString(),
            sort: sortBy.value,
            order_by: orderBy.value,
          },
          1
        )
          .then(() => setSearchLoading(false))
          .catch(() => {
            setSearchLoading(false);
          });
      })
      .catch(() => {
        setSearchLoading(false);
      });
    return () => clearHashtag();
  }, []);

  function onSubmitData(e) {
    e.preventDefault();
    console.log("brand", brand);
    if (brand) {
      setSearchLoading(true);
      getHashtag(
        {
          hashtag_id: brand,
          from_date: startDate.toString(),
          to_date: endDate.toString(),
          sort: sortBy.value,
          order_by: orderBy.value,
        },
        1
      )
        .then(() => {
          setSearchLoading(false);
        })
        .catch(() => {
          setSearchLoading(false);
        });
    }
  }

  //   const searchMarketPlace = (e) => {
  //     setSearchLoading(true);
  //     setLoading(true);
  //     setCurrentPage(0);
  //     e.preventDefault();
  //     getMarketPlace(
  //       1,
  //       limit,
  //       category.value,
  //       sortBy.value,
  //       orderBy.value,
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(
  //       function () {
  //         setLoading(false);
  //         setSearchLoading(false);
  //       },
  //       function (error) {
  //         setLoading(false);
  //         setSearchLoading(false);
  //         toast.error(error?.response?.data?.message);
  //       }
  //     );
  //   };

  const clearMarketPlace = (e) => {
    setClearLoading(true);
    // setBrand({ value: "all", label: "ALL" });
    // setBrand("");
    setSortBy({ value: 'date', label: 'DATE' });
    setOrderBy({ value: 'desc', label: 'DESC' });
    setStartDate(
      moment().subtract(2, 'year').startOf('year').format('YYYY-MM-DD')
    );
    setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    // clearHashtag();
    getHashtag({
      hashtag_id: brand,
      from_date: fromDate.toString(),
      to_date: toDate.toString(),
      sort: 'date',
      order_by: 'desc',
    }).then(() => {
      setClearLoading(false);
    });
  };

  //   const handlePageClick = (e) => {
  //     const page = e.selected;
  //     setCurrentPage(page);
  //     getMarketPlace(
  //       page + 1,
  //       limit,
  //       category.value,
  //       sortBy.value,
  //       orderBy.value,
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(function () {
  //       setLoading(false);
  //     });
  //   };

  const style = {
    control: (base) => ({
      ...base,
      height: '40px',
      boxShadow: 'none',
      borderRadius: '0px',
      border: '1px solid black',
      '&:hover': {
        border: '1px solid black',
      },
    }),
  };

  const sortByOptions = [
    { value: 'date', label: 'DATE' },
    { value: 'followers', label: 'MOST INFLUENTIAL' },
    // { value: "likes", label: "LIKES" },
    // { value: "comments", label: "COMMENTS" },
  ];
  const sortOrderOptions = [
    { value: 'asc', label: 'ASC' },
    { value: 'desc', label: 'DESC' },
  ];

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // if (!loading) {
  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">Monitor</h1>
        </div>
        <Row className="post-analytics-tab mb-">
          <Col xs={12} xl={12} md={12}>
            <form onSubmit={onSubmitData}>
              <Row>
                <Col className="col-xl-3" xs={12} md={4}>
                  {/* <Form.Item
                    name="datetime"
                    initialValue={'date'}
                    labelCol={{ span: 24 }}
                    label="Select Start Date / End Date"
                  > */}
                  <p>Select Start Date / End Date</p>
                  <RangePicker
                    size="large"
                    className="w-100"
                    key={4}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : []
                    }
                    allowClear={false}
                    ranges={{
                      Today: [moment(), moment()],
                      Tomorrow: [
                        moment().add(1, 'days'),
                        moment().add(1, 'days'),
                      ],
                      Yesterday: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      'Last Month': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                  {/* </Form.Item> */}
                </Col>
                {/* <Col xs={12} xl md={6}>
                  <p>Hashtags</p>
                  <Select
                    value={brand}
                    name="sort"
                    className="selectCustomization"
                    options={[
                      // { hashtag_id: "all", hashtag: "ALL" },
                      ...hashtags.message,
                    ].map((item) => {
                      return {
                        value: item.hashtag_id,
                        label: item.hashtag,
                      };
                    })}
                    onChange={(e) => {
                      setBrand(e);
                    }}
                    placeholder="Select Hashtags"
                    styles={style}
                    isSearchable={false}
                  />
                </Col> */}
                {/* <Col xs={12} xl={2} md={6}>
                    <p>Select Category</p>
                    <Select
                      value={category}
                      name="sort"
                      className="selectCustomization"
                      options={categoryOptions}
                      onChange={(e) => {
                        setCategory(e);
                      }}
                      placeholder="Select Category"
                      styles={style}
                    />
                  </Col> */}

                <Col className="col-xl-3" xs={12} md={4}>
                  {/* <Form.Item labelCol={{ span: 24 }} label="Sort By" name="sort"> */}
                  <p>Sort By</p>
                  <Select
                    size="large"
                    value={sortBy}
                    name="sort"
                    className="selectCustomization select-style dark-field"
                    options={sortByOptions}
                    onChange={(e) => {
                      setSortBy(e);
                    }}
                    placeholder="Sort By"
                    styles={style}
                    isSearchable={false}
                  />
                  {/* </Form.Item> */}
                </Col>
                {/* <Col xs={12} xl md={6}>
                  <p>Order By</p>
                  <Select
                    value={orderBy}
                    name="order"
                    className="selectCustomization"
                    options={sortOrderOptions}
                    onChange={(e) => {
                      setOrderBy(e);
                    }}
                    placeholder="Order By"
                    styles={style}
                    isSearchable={false}
                  />
                </Col> */}
                {/* <Col xs={12} xl={2} md={6}>
                    <p>Order By</p>
                    <Select
                      value={orderBy}
                      name="sort"
                      className="selectCustomization"
                      options={sortOrderOptions}
                      onChange={(e) => {
                        setOrderBy(e);
                      }}
                      placeholder="Order By"
                      styles={style}
                    />
                  </Col> */}
                <Col className="d-flex align-items-end col-xl-3" xs={12} md={4}>
                  {searchLoading ? (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{' '}
                      Search
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn"
                    >
                      Search
                    </Button>
                  )}
                  {clearLoading ? (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{' '}
                      Reset
                    </Button>
                  ) : (
                    <Button
                      onClick={clearMarketPlace}
                      className="default-btn outline fltr-hpr  medium-btn  res-btn2"
                      type="primary"
                      size="large"
                    >
                      Reset
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <hr className="separator-line" />
        <div style={{ marginTop: 20 }}>
          {searchLoading || clearLoading ? (
            <div
              style={{
                height: 300,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className="spinclass loader-center position-relative">
                <Spin size="large" />
              </span>
            </div>
          ) : hashtag.message.length > 0 ? (
            <InfiniteScroll
              dataLength={hashtag.message.length}
              next={() => {
                getHashtag(
                  {
                    hashtag_id: brand.value,
                    from_date: startDate.toString(),
                    to_date: endDate.toString(),
                    sort: sortBy.value,
                    order_by: orderBy.value,
                  },
                  hashtag.pagination.next?.page,
                  true
                );
              }}
              // hasMore={hashtag.pagination.next ? true : false}
              hasMore={
                hashtag.pagination.next
                  ? hashtag.pagination.next?.page >= 1
                    ? false
                    : true
                  : false
              }
              loader={
                <div
                  style={{
                    height: 100,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                  </span>
                </div>
              }
            // pullDownToRefreshThreshold={50}
            >
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    350: 1,
                    700: 2,
                    1100: 3,
                    1500: 4,
                  }}
                >
                  <Masonry gutter="15px">
                    {hashtag.message.map((item) => {
                      return <Box data={item} />;
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </InfiniteScroll>
          ) : (
            <div
              style={{
                height: 300,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NoDataFound />
            </div>
          )}
        </div>
        {/* {!loading ? (
            marketPlace?.message?.length > 0 ? (
              <>
                <Row className="post-analytics-tab-boxes-ift">
                  {marketPlace.message.map((item, index) => (
                    <Box
                      key={index}
                      userInfo={userInfo}
                      addCampaignToShop={addCampaignToShop}
                      item={item}
                      index={index}
                    />
                  ))}
                </Row>
                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  pageClassName="page-item "
                  pageLinkClassName="page-link custom-paginate-link btn btn-primary"
                  previousClassName="page-item"
                  previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
                  nextClassName="page-item"
                  nextLinkClassName="page-link custom-paginate-next btn btn-primary"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  forcePage={currentPage}
                  pageCount={Math.ceil(marketPlace.totalCount / limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "pagination justify-content-center mt-2 custom-paginate"
                  }
                  // subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </>
            ) : (
              
            )
          ) : (
            <Loader size={40} />
          )} */}
      </div>
    </>
  );
}

function mapStateToProps({ hashtags, hashtag }) {
  return { hashtags, hashtag };
}

export default connect(mapStateToProps, hashActions)(TopHashTag);
