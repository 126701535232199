import React, { useState, useEffect } from 'react';
import * as profileActions from '../../redux/actions/searchProfile';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Button, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { Card, CardContent } from '@mui/material';
import moment from 'moment';
import { DatePicker, Spin, Form } from 'antd';
import NoDataFound from '../../../src/components/NoDataFound/NoDataFound';
import { Typography } from '@mui/material';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Box from './Box/Box';
import { getMyProfile } from '../../redux/actions/myProfile';
const MyProfile = () => {
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [error, setError] = useState(false);

    const [searchLoading, setSearchLoading] = useState(false);
    const [clearLoading, setClearLoading] = useState(false);
    const [sortBy, setSortBy] = useState({ value: 'timestamp', label: 'DATE' });
    const [orderBy, setOrderBy] = useState({ value: 'desc', label: 'DESC' });
    const [posts, setPosts] = useState({ value: '25', label: '25' });
    const [spinner, setLoad] = useState(true);
    const fromDate = moment().subtract(4, 'year').format('YYYY-MM-DD');
    const toDate = moment(new Date()).format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(toDate);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const { myProfile } = useSelector((state) => { return state; })

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMyProfile()).then((res) => {
            console.log("res", res);
            setLoad(false)
        })
    }, [])
    console.log("myProfile", myProfile);

    function intlFormat(num) {
        return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
    }
    function numberFormat(num) {
        if (num >= 1000000) return intlFormat(num / 1000000) + 'M';
        if (num >= 1000) return intlFormat(num / 1000) + 'k';
        return intlFormat(num);
    }

    return (
        spinner ? <span className="spinclass loader-center position-relative">
            <Spin size="large" />
        </span>
            :
            <>
                <Card sx={{ marginTop: 2 }}>
                    <CardContent>
                        <Row>
                            <Col
                                xl={2}
                                lg={3}
                                sm={12}
                                className="d-flex flex-column justify-content-center align-items-center"
                            >
                                <Card
                                    elevation={3}
                                    sx={{ maxWidth: 200, borderRadius: '50%' }}
                                >
                                    <img
                                        style={{ width: '100%' }}
                                        src={myProfile?.payload?.Profileinfo?.profile_picture_url}
                                    />
                                </Card>
                            </Col>
                            <Col
                                xl={10}
                                lg={9}
                                sm={12}
                                className="d-flex flex-column justify-content-center"
                            >
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    sx={{ color: 'gray', marginTop: 1 }}
                                >
                                    <a
                                        target="_blank"
                                        href={`https://www.instagram.com/${myProfile?.payload?.Profileinfo?.username}/`}
                                        style={{ color: 'gray' }}
                                    >
                                        {myProfile?.payload?.Profileinfo?.username}
                                    </a>
                                </Typography>
                                {/* <Paper variant='outlined'> */}
                                <div style={{ display: 'flex' }}>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            backgroundColor: '#e8e8e8',
                                            padding: 1,
                                            borderRadius: 1,
                                            marginRight: 2,
                                        }}
                                    >
                                        {numberFormat(myProfile?.payload?.Profileinfo?.followers_count)} Followers
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            backgroundColor: '#e8e8e8',
                                            padding: 1,
                                            borderRadius: 1,
                                            marginRight: 2,
                                        }}
                                    >
                                        {numberFormat(myProfile?.payload?.Profileinfo?.follows_count)} Following
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            backgroundColor: '#e8e8e8',
                                            padding: 1,
                                            borderRadius: 1,
                                            marginRight: 2,
                                        }}
                                    >
                                        {numberFormat(myProfile?.payload?.Profileinfo?.media_count)} Posts
                                    </Typography>
                                </div>
                                <div className="mt-2">
                                    <Typography variant="body" component="div" color="gray">
                                        Biography:{' '}
                                    </Typography>
                                    <Typography variant="body" color="gray">
                                        {myProfile?.payload?.Profileinfo?.biography}
                                    </Typography>
                                </div>
                                <div className="mt-2">
                                    <Typography variant="body" component="div" color="gray">
                                        Website:{' '}
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        sx={{
                                            color: 'gray',
                                            marginTop: 1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <a target="_blank" href={myProfile?.payload?.Profileinfo?.website}>
                                            {/* {myProfile?.payload?.Profileinfo?.website?.slice(0, 40)}... */}
                                            {myProfile?.payload?.Profileinfo?.website}
                                        </a>
                                    </Typography>
                                </div>
                                {/* </Paper> */}
                            </Col>
                        </Row>
                    </CardContent>
                </Card>


                <hr className="separator-line" />
                <div className="mt-3">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 700: 2, 1100: 3, 1500: 4 }}
                    >
                        <Masonry gutter="15px">
                            {myProfile?.payload?.timeline?.data?.map((item) => {
                                return <Box data={item} />;
                            })}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </>
    );
}

export default MyProfile