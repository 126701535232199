import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useLocation,
} from 'react-router-dom';
import '../../css/controlRoom.scss';
import {
  Input,
  Form,
  Select,
  Button,
  Tabs,
  Dropdown,
  Menu,
  Skeleton,
  Checkbox,
  Row,
  Modal,
  Switch,
  Spin,
  Radio,
  notification,
  Tooltip,
} from 'antd';
import { useSelector, useDispatch, connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getEventsBy_Id,
  getEvents,
  deleteEvent,
  hostCheck
} from '../../redux/actions/event.action';
import {
  goLive,
  stopLive,
  sendMetaData,
  getMetaData,
  getBroadcastClientRequest,
  saveControl,
  getControl,
  startMeetingRequest,
  endMeetingRequest,
  goFbLive
} from '../../redux/actions/broadcast.action';
import {
  LoadingOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  faAngleLeft,
  faBullhorn,
  faEye,
  faPause,
  faPlay,
  faPenToSquare,
  faBan,
  faCode,
  faUsers,
  faHeart,
  faTag,
  faBoxOpen,
  faPlus,
  faCirclePlay,
  faXmark,
  faVideo,
  faHeadphonesSimple,
  faGear,
  faMicrophone,
  faMicrophoneSlash,
  faArrowUpRightFromSquare,
  faUserTie,
  faUserPen,
  faUser,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
// import { useSearchParams } from "react-router-dom";
import logo from '../../images/ormelogo.svg';
import BroadCast from './videoSection';
import live1 from '../../images/kbshop_audience.svg';
import live2 from '../../images/kbshop_buyproduct.svg';
import live3 from '../../images/kbshop_chat.svg';
import stream from '../../images/stream.png';
import streamGrey from '../../images/streamGrey.png';
import { CalendarFilled } from '@ant-design/icons';
import { ShareUrl } from '../../redux/actions/shareUrl.action';
import StartMeetingScreen from './StartMeetingScreen';
import { CardImg } from 'react-bootstrap';
import {
  useNavigate,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import IVSBroadcastClient, { Errors } from 'amazon-ivs-web-broadcast';
import ReactHlsPlayer from 'react-hls-player';
import MixerScreen from './MixerScreen';
import ProducerMixerScreen from './ProducerMixerScreen';
import EventTimer from './EventTimer';
import Chat from './chat';
import * as chatActions from '../../redux/actions/chat';
import { getChat } from '../../redux/actions/chat';

const { TabPane } = Tabs;

// const handleButtonClick = (e) => {
//   // message.info('Click on left button.');
//   // console.log('click left button', e);
// };

const { Option } = Select;
// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };
const { confirm } = Modal;

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

function ControlRoom({ createChatToken }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [top, setTop] = useState(15);
  const dispatch = useDispatch();
  const history = useNavigate();

  const [spinner, setSpin] = useState(true);
  const [cameraPreview, setCameraPreview] = useState(false);
  const [startbroadcast, setStartBroadcast] = useState(false);
  const [dateTime, setDate] = useState('');
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState('');
  const [title, setTitle] = useState('');
  const [liveData, setLiveData] = useState('');
  const [modal2Visible, settingsModal] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [mirror, setMirror] = useState(false);
  const [mute, setMute] = useState(false);
  const [error, setError] = useState('');
  const [endSession, setEndSession] = useState(false);
  const [endLiveLoading, setEndLiveLoading] = useState(false);
  const [settingPreview, setSettingPreview] = useState(false);
  const [videoDevices, setVideoDevices] = useState();
  const [audioDevices, setAudioDevices] = useState();
  const [audioOutputDevices, setAudioOutputDevices] = useState();
  const [screenView, setScreenView] = useState('landscape');
  const [mirrorVideo, setMirrorVideo] = useState(true);
  const [videoDeviceID, setVideoDeviceID] = useState('');
  const [audioMicrophoneID, setAudioMicrophoneID] = useState('');
  const [controlData, setControlData] = useState('');
  const [meeting, setMeeting] = useState(false);
  const [meetingData, setMeetingData] = useState('');
  const [producer, setProducer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [endMeetingFlag, setEndMeetingFlag] = useState(false);
  const [live, setLive] = useState('');
  const [chat, setChat] = useState([]);
  const [height, setHeight] = useState(495);
  const [startLoading, setStartLoading] = useState(false);


  let [isBlocking, setIsBlocking] = useState(false);

  const { getEventById, getBroadcastClient, shareUrls } = useSelector(
    (state) => {
      return state;
    }
  );
  // const navigate = useNavigate();
  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
    setIsModalOpen(false);
    setUrlModal(false);
  };

  const shareKey = (id, status) => {
    let UrlKey = '';
    if (status === 'live') {
      UrlKey = 'live-event';
    } else {
      UrlKey = 'upcoming-event';
    }
    let url = `https://www.kbshop.com/${UrlKey}/${id}/${userInfo?.username ? userInfo?.username : userInfo?.pixel_id
      }`;
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setIsModalOpen(true);
      } else {
        console.log('err');
      }
    });
  };

  const params = useParams();
  const id = params.id;

  const chatRef = useRef();

  // usePrompt(
  //   "If you proceed Braodcast will be stop, Are you sure you want to proceed?",
  //   isBlocking,
  //   id
  // );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setHeight(event[0].contentBoxSize[0].blockSize);
    });

    if (chatRef && chatRef.current) {
      resizeObserver.observe(chatRef.current);
    }
  }, [chatRef, meeting]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const productUrl = (url) => {
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setUrlModal(true);
      } else {
        console.log('err');
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(hostCheck(id, false));
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEventsBy_Id(id, true)).then((res) => {
        if (res.payload.success) {
          setProducer(res.payload?.message[0]?.producer);
          if (res.payload?.message[0]?.meeting_info) {
            setMeetingData(res.payload?.message);
            setTimeout(() => {
              setMeeting(true);
            }, 1000);
          } else {
            setMeeting(false);
          }
          setEventEnded(res.payload?.message);
          setBanner(res.payload?.message[0]?.event?.banner);
          setDate(res.payload?.message[0]?.event?.start_date);
          setTitle(res.payload?.message[0]?.event?.title);
          setSpin(false);
          if (!res.payload?.message[0]?.producer) {
            dispatch(hostCheck(id, true));
          }
        }
      });

      dispatch(getBroadcastClientRequest(id));

      // handlePermissions();

      // dispatch(getControl(id)).then((res) => {
      //   if (res.payload.success) {
      //     setControlData(res.payload?.message);
      //     setScreenView(
      //       res.payload?.message?.broadcast_settings
      //         ? res.payload?.message?.broadcast_settings?.screenview
      //         : "landscape"
      //     );
      //     setMirrorVideo(
      //       res.payload?.message?.broadcast_settings
      //         ? res.payload?.message?.broadcast_settings?.mirrorVideo
      //         : true
      //     );
      //   }
      // });
      // dispatch(getMetaData(id)).then((res) => {
      //   const mir = res.payload?.data?.meta_data?.mirror ? true : false;
      //   setMirror(mir);
      // });
    }
  }, [id]);

  // const ingestEndpoint = getBroadcastClient?.payload?.message?.ingestEndpoint;
  // const streamKey = getBroadcastClient?.payload?.message?.streamKey;

  // const client = IVSBroadcastClient.create({
  //   streamConfig: IVSBroadcastClient.STANDARD_LANDSCAPE,
  //   // streamConfig:
  //   //   screenView === "landscape"
  //   //     ? IVSBroadcastClient.BASIC_LANDSCAPE
  //   //     : IVSBroadcastClient.BASIC_PORTRAIT,

  //   ingestEndpoint: ingestEndpoint,
  // });

  useEffect(() => {
    if (meeting == 'start') {
      setStartLoading(true);
      dispatch(startMeetingRequest(id)).then((res) => {
        dispatch(getEventsBy_Id(id)).then((res) => {
          if (res.payload.success) {
            setSpin(false);
            setStartLoading(false);
            setMeetingData(res.payload?.message);
            setEventEnded(res.payload?.message);
            setBanner(res.payload?.message[0]?.event?.banner);
            setDate(res.payload?.message[0]?.event?.start_date);
            setTitle(res.payload?.message[0]?.event?.title);
          }
        });
      });
    }
  }, [meeting]);

  function endMeeting(cb) {
    setEndMeetingFlag(true);
    return dispatch(endMeetingRequest(id, cb, () => dispatch(stopLive(id))));
    // client.stopBroadcast()
  }
  function onGolive() {
    dispatch(goLive(id)).then((res) => {
      dispatch(getEventsBy_Id(id)).then((res) => {
        setLive(res.payload?.message[0]?.event_status);
        if (res.payload?.message[0]?.event?.is_facebook_live) {
          goFbLive(id)
        }
      });

      notification.success({
        message: 'You are Live!',
        className: 'toast-success',
      });
    });
  }

  // useEffect(() => {
  //   if (startbroadcast) {
  //     stopCamera();
  //     startLiveBroadcast();
  //   } else {
  //     // stopLiveBroadcast();
  //   }
  // }, [startbroadcast]);

  // useEffect(() => {
  //   if (cameraPreview) {
  //     startCamera();
  //   } else {
  //     stopCamera();
  //   }
  // }, [cameraPreview]);

  // useEffect(() => {
  //   if (settingPreview) {
  //     settingStartCamera();
  //   } else {
  //     settingStopCamera();
  //   }
  // }, [settingPreview]);

  // useEffect(() => {
  //   if (settingPreview) {
  //     settingStartCamera();
  //   } else {
  //     settingStopCamera();
  //   }
  // }, [screenView, settingPreview]);

  // async function handlePermissions() {
  //   let permissions = {
  //     audio: false,
  //     video: false,
  //   };
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //       audio: true,
  //     });
  //     for (const track of stream.getTracks()) {
  //       track.stop();
  //     }
  //     permissions = { video: true, audio: true };
  //     const devices = await navigator.mediaDevices.enumerateDevices();
  //     window.videoDevices = devices.filter((d) => d.kind === "videoinput");
  //     window.audioDevices = devices.filter((d) => d.kind === "audioinput");
  //     window.audioOutputDevices = devices.filter(
  //       (d) => d.kind === "audiooutput"
  //     );
  //     setVideoDevices(window?.videoDevices);
  //     setAudioDevices(window?.audioDevices);
  //     setAudioOutputDevices(window?.audioOutputDevices);
  //   } catch (err) {
  //     permissions = { video: false, audio: false };
  //     console.error(err.message);
  //   }
  //   // If we still don't have permissions after requesting them display the error message
  //   if (!permissions.video) {
  //     console.error("Failed to get video permissions.");
  //     setError("Failed to get video.");
  //     setCameraError(true);
  //   } else if (!permissions.audio) {
  //     console.error("Failed to get audio permissions.");
  //     setError("Failed to get audio.");
  //     setCameraError(true);
  //   }
  // }

  // async function startCamera() {
  //   // const devices = await navigator.mediaDevices.enumerateDevices();
  //   // window.videoDevices = devices.filter((d) => d.kind === "videoinput");
  //   // window.audioDevices = devices.filter((d) => d.kind === "audioinput");

  //   const streamConfig =
  //     screenView === "landscape"
  //       ? IVSBroadcastClient.STANDARD_LANDSCAPE
  //       : IVSBroadcastClient.BASIC_PORTRAIT;

  //   console.log(streamConfig, "streamCamera");

  //   if (navigator.mediaDevices.getUserMedia) {
  //     window.cameraStream = await navigator.mediaDevices.getUserMedia({
  //       video: {
  //         deviceId: window.videoDevices[0].deviceId,
  //         width: {
  //           ideal: streamConfig.maxResolution.width,
  //           max: streamConfig.maxResolution.width,
  //         },
  //         height: {
  //           ideal: streamConfig.maxResolution.height,
  //           max: streamConfig.maxResolution.height,
  //         },
  //       },
  //     });
  //   }
  //   const videoTag = document.getElementById("preview");
  //   videoTag.srcObject = window.cameraStream;
  // }

  // async function stopCamera() {
  //   const videoTag = document.getElementById("preview");
  //   if (videoTag !== null) {
  //     const stream = videoTag.srcObject;
  //     if (stream !== null) {
  //       const tracks = stream.getTracks();

  //       if (tracks) {
  //         tracks.forEach((track) => {
  //           track.stop();
  //         });
  //         videoTag.srcObject = null;
  //       }
  //     }
  //   }
  // }

  // async function settingStartCamera() {
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   window.videoDevices = devices.filter((d) => d.kind === "videoinput");
  //   window.audioDevices = devices.filter((d) => d.kind === "audioinput");

  //   const streamConfig =
  //     screenView === "landscape"
  //       ? IVSBroadcastClient.STANDARD_LANDSCAPE
  //       : IVSBroadcastClient.BASIC_PORTRAIT;

  //   console.log(streamConfig, "settingStartCamera");

  //   if (navigator.mediaDevices.getUserMedia) {
  //     window.cameraStream = await navigator.mediaDevices.getUserMedia({
  //       video: {
  //         deviceId: window.videoDevices[0].deviceId,
  //         width: {
  //           ideal: streamConfig.maxResolution.width,
  //           max: streamConfig.maxResolution.width,
  //         },
  //         height: {
  //           ideal: streamConfig.maxResolution.height,
  //           max: streamConfig.maxResolution.height,
  //         },
  //       },
  //     });
  //   }
  //   const videoTag = document.getElementById("settingpreview");
  //   videoTag.srcObject = window.cameraStream;
  // }

  // async function settingStopCamera() {
  //   const videoTag = document.getElementById("settingpreview");
  //   if (videoTag !== null) {
  //     const stream = videoTag.srcObject;
  //     if (stream !== null) {
  //       const tracks = stream.getTracks();

  //       if (tracks) {
  //         tracks.forEach((track) => {
  //           track.stop();
  //         });
  //         videoTag.srcObject = null;
  //       }
  //     }
  //   }
  // }

  // async function startLiveBroadcast() {
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   window.videoDevices = devices.filter((d) => d.kind === "videoinput");
  //   window.audioDevices = devices.filter((d) => d.kind === "audioinput");

  //   // const streamConfig = IVSBroadcastClient.STANDARD_LANDSCAPE;
  //   const streamConfig =
  //     screenView === "landscape"
  //       ? IVSBroadcastClient.STANDARD_LANDSCAPE
  //       : IVSBroadcastClient.BASIC_PORTRAIT;

  //   console.log(streamConfig, "streamConfig");

  //   if (navigator.mediaDevices.getUserMedia) {
  //     window.cameraStream = await navigator.mediaDevices.getUserMedia({
  //       video: {
  //         deviceId: window.videoDevices[0].deviceId,
  //         width: {
  //           ideal: streamConfig.maxResolution.width,
  //           max: streamConfig.maxResolution.width,
  //         },
  //         height: {
  //           ideal: streamConfig.maxResolution.height,
  //           max: streamConfig.maxResolution.height,
  //         },
  //       },
  //     });
  //     window.microphoneStream = await navigator.mediaDevices.getUserMedia({
  //       audio: { deviceId: window.audioDevices[0].deviceId },
  //     });
  //   }
  //   var element = document.getElementById("count");
  //   element.classList.add("d-block");

  //   setTimeout(() => {
  //     client.addVideoInputDevice(
  //       window.cameraStream,
  //       window.videoDevices[0].deviceId,
  //       { index: 0 }
  //     );
  //     client.addAudioInputDevice(
  //       window.microphoneStream,
  //       window.audioDevices[0].deviceId
  //     );

  //     client
  //       .startBroadcast(streamKey)
  //       .then((result) => {
  //         console.log("I am successfully broadcasting!");
  //         const videoTag = document.getElementById("preview");
  //         videoTag.srcObject = window.cameraStream;
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "Something drastically failed while broadcasting!",
  //           error
  //         );
  //         setError("Something drastically failed while broadcasting!");
  //       });
  //     //   setCount(false);
  //     var element = document.getElementById("count");
  //     element.classList.remove("d-block");
  //   }, 3500);
  // }

  // async function stopLiveBroadcast() {
  //   setEndSession(true);
  //   setStartBroadcast(!startbroadcast);
  //   setLiveData("");
  //   const videoTag = document.getElementById("preview");
  //   console.log(videoTag, "videoTag");
  //   if (videoTag !== null) {
  //     const stream = videoTag.srcObject;
  //     console.log(stream, "stream");
  //     if (stream !== null) {
  //       const tracks = stream.getTracks();
  //       if (tracks) {
  //         tracks.forEach((track) => {
  //           track.stop();
  //         });
  //       }
  //       // client.stopBroadcast();
  //       const devices = await navigator.mediaDevices.enumerateDevices();
  //       window.videoDevices = devices.filter((d) => d.kind === "videoinput");
  //       window.audioDevices = devices.filter((d) => d.kind === "audioinput");
  //       client.stopBroadcast();
  //       client.removeVideoInputDevice(window.videoDevices[0].deviceId);
  //       client.removeAudioInputDevice(window.audioDevices[0].deviceId);
  //       videoTag.srcObject = null;
  //       setSpin(true);
  //       // if (endSession) {
  //       dispatch(stopLive(id)).then((res) => {
  //         if (res.payload.success) {
  //           // setEndLiveLoading(false);
  //           // navigate(0);
  //           // setSpin(true);
  //           dispatch(getEventsBy_Id(id)).then((res) => {
  //             if (res.payload.success) {
  //               setSpin(false);
  //               setEventEnded(res.payload?.message);
  //               setBanner(res.payload?.message[0]?.banner);
  //               setDate(res.payload?.message[0]?.start_date);
  //               setTitle(res.payload?.message[0]?.title);
  //             }
  //           });
  //         } else {
  //           console.log(res.payload, "error");
  //         }
  //       });
  //       // }
  //     }
  //   }
  // }

  // async function funcMute() {
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   window.audioDevices = devices.filter((d) => d.kind === "audioinput");
  //   let audioStream = client.getAudioInputDevice(
  //     window.audioDevices[0].deviceId
  //   );
  //   audioStream.getAudioTracks()[0].enabled = false;
  //   var mute = document.getElementById("mute");
  //   mute.classList.add("d-none");
  //   mute.classList.remove("d-block");
  //   var unmute = document.getElementById("unmute");
  //   unmute.classList.add("d-block");
  //   unmute.classList.remove("d-none");
  // }

  // async function funcUnMute() {
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   window.audioDevices = devices.filter((d) => d.kind === "audioinput");
  //   let audioStream = client.getAudioInputDevice(
  //     window.audioDevices[0].deviceId
  //   );
  //   audioStream.getAudioTracks()[0].enabled = true;
  //   var mute = document.getElementById("mute");
  //   mute.classList.add("d-block");
  //   mute.classList.remove("d-none");
  //   var unmute = document.getElementById("unmute");
  //   unmute.classList.add("d-none");
  //   unmute.classList.remove("d-block");
  // }

  // const onSetting = (key) => {
  //   if (key === "audio") {
  //     settingStopCamera();
  //   } else if (key === "video") {
  //     settingStartCamera();
  //   }
  // };

  // const onScreenView = (e) => {
  //   setScreenView(e.target.value);
  // };

  // const onMirrorVideo = (e) => {
  //   setMirrorVideo(e.target.checked);
  // };

  // const onSaveControl = () => {
  //   console.log({
  //     screenview: screenView,
  //     mirrorVideo: mirrorVideo,
  //     videoDeviceID: videoDeviceID,
  //     audioMicrophoneID: audioMicrophoneID,
  //   });
  //   let data = {
  //     screenview: screenView,
  //     mirrorVideo: mirrorVideo,
  //   };
  //   dispatch(saveControl(id, data)).then((res) => {
  //     console.log(res.payload, "res");
  //     if (res.payload.success) {
  //       setSettingPreview(!settingPreview);
  //       settingsModal(false);
  //       notification.success({
  //         message: res.payload?.message,
  //         className: "toast-success",
  //       });
  //       dispatch(getControl(id)).then((res) => {
  //         if (res.payload.success) {
  //           setControlData(res.payload?.message);
  //           setScreenView(res.payload?.message?.broadcast_settings?.screenview);
  //           setMirrorVideo(
  //             res.payload?.message?.broadcast_settings?.mirrorVideo
  //           );
  //         }
  //       });
  //     } else {
  //       console.log(res.payload, "error");
  //     }
  //   });
  // };

  // const handleVideoDevice = (value) => {
  //   setVideoDeviceID(value);
  // };

  // const handleAudioMicrophone = (value) => {
  //   setAudioMicrophoneID(value);
  // };

  const handleButtonClick = (e) => {
    if (e.key === 'cancel') {
      confirm({
        wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
        title: 'Warning',
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: 'Are you sure you want to cancel this event ?',
        okText: ' Yes, cancel',
        okType: 'danger',
        cancelText: " No, I don't want to cancel ",

        onOk() {
          return new Promise((resolve, reject) => {
            dispatch(deleteEvent(id)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: res.payload?.message,
                  className: 'toast-success',
                });

                setTimeout(resolve, 100);
                history({
                  pathname: `/events`,
                });
              } else {
                console.log(res.payload.data.message, 'err');
                notification.error({
                  message: res.payload.data.message,
                  className: 'toast-error',
                });
              }
            });
          });
        },

        onCancel() {
          console.log('Cancel');
        },
      });
    }
    if (e.key === 'edit') {
      history({
        pathname: `/schedule-event-id/${id}`,
      });
    }
  };

  const onKeyChange = (key) => {
    if (key == '1' && id && meeting && meetingData.length > 0) {
      createChatToken(id);
      dispatch(getChat(id)).then((res) => {
        if (res.payload.success) {
          setChat(res.payload.message?.data);
        } else {
          notification.error({
            message: res.payload.data.message,
            className: 'toast-error',
          });
        }
      });
    }
  };

  if (!spinner) {
    return (
      <>
        <div className="control-room-top-area mb-4">
          <div className="control-top-box">
            <Link
              to="/events?type=Upcoming"
              className="prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span>Go back</span>
            </Link>
          </div>
          <div className="control-top-box">
            {/* <a  
                  className="prev-next-link ff-nunito w-100-mob get-tour-space"
                  href="#"
                >
                  <FontAwesomeIcon icon={faBullhorn} />
                  <span>Get a tour of the control room</span>
                </a>
                <Link
                  to="#"
                  className="default-btn color-secondary mob-style  d-md-flex align-items-center justify-content-center m-0"
                >
                  <FontAwesomeIcon icon={faEye} />
                  <span className="nav-text">Preview Event</span>
                </Link> */}
            <a
              onClick={() =>
                shareKey(
                  getEventById?.payload?.message[0]?.event_id,
                  getEventById?.payload?.message[0]?.event?.event_status
                )
              }
              // href={`https://www.kbshop.com/${getEventById?.payload?.message[0]?.event?.event_status == "live"
              //   ? "live-event"
              //   : liveData
              //     ? "live-event"
              //     : "upcoming-event"
              //   }/${id}/${userInfo?.username}`}
              className="default-btn outline mob-style d-md-flex align-items-center justify-content-center m-0"
              target="_blank"
            >
              {/* <FontAwesomeIcon icon={faEye} /> */}
              {/* <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> */}
              <FontAwesomeIcon icon={faShareNodes} />

              <span className="nav-text">Share Link</span>
            </a>
            {/* <Dropdown.Button
                  trigger="click"
                  overlayClassName="control-room-drowpdown"
                  size="large"
                  onClick={handleButtonClick}
                  overlay={menu}
                ></Dropdown.Button> */}

            {producer && (
              <Dropdown.Button
                trigger="click"
                overlayClassName="control-room-drowpdown"
                size="large"
                disabled={
                  getEventById?.payload?.message[0]?.event?.event_status ==
                    'live'
                    ? true
                    : liveData
                      ? true
                      : false
                }
                // onClick={() => handleButtonClick(item)}
                // overlay={menu}
                overlay={
                  <Menu onClick={(e) => handleButtonClick(e)}>
                    <Menu.Item key="edit">
                      <span>Edit</span>
                    </Menu.Item>
                    <Menu.Item key="cancel">
                      <span>Cancel</span>
                    </Menu.Item>
                  </Menu>
                }
              ></Dropdown.Button>
            )}
          </div>
        </div>

        <div className="event-preview-box-main mb-30">
          {!getEventById.loading ? (
            <div className="row">
              <div className="col-lg-3 d-lg-flex align-items-center">
                {/* <div className="event-banner" style={{ backgroundImage: `url(${getEventById?.payload?.message[0]?.banner})` }} > */}
                <div className="event-banner">
                  <div className="event-box w-100 h-100 overflow-hidden">
                    <div className="event-banner-img">
                      <img
                        src={getEventById?.payload?.message[0]?.event?.banner}
                        alt="banner"
                      />
                    </div>
                    <div>
                      <div className="schedule-badge">
                        {live === 'live' ||
                          meetingData[0]?.event_status === 'live'
                          ? 'Live'
                          : 'Preview'}
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-end">
                  <div className="ribbon-small">
                    <FontAwesomeIcon icon={faFlask} />
                    <span>Event </span>
                  </div>
                </div> */}
                  </div>
                </div>

                <div className="preview-date-main">
                  <h4 className="date-txt">Date</h4>
                  <h5 className="preview-date">
                    {' '}
                    {moment(
                      getEventById?.payload?.message[0]?.event?.start_date
                    )
                      .utc()
                      .format('YYYY-MM-DD')}
                  </h5>
                  <h6 className="preview-time">
                    At{' '}
                    {moment(
                      getEventById?.payload?.message[0]?.event?.start_date
                    ).format('hh:mm a')}
                  </h6>
                </div>
              </div>
              <div className="col-lg-2 preview-data-border  d-lg-flex align-items-center">
                <div className="preview-date-main">
                  <h4 className="date-txt">Event</h4>
                  <h5 className="preview-date">
                    {getEventById?.payload?.message[0]?.event?.title}
                  </h5>
                  <div className="event-detail-txt">
                    <CalendarFilled /> LIVE-SHOPPING-EVENT
                  </div>
                </div>
                <div></div>
              </div>
              <div className="col-lg-3 preview-data-border d-lg-flex align-items-center">
                <div className="preview-date-main producer-style">
                  <h4 className="date-txt"></h4>

                  {getEventById?.payload?.message[0]?.hosts.map((item, i) => {
                    return (
                      <>
                        {item.producer && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUserTie} />{' '}
                            Producer:{' '}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.primary && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{' '}
                            Primary:{' '}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host1 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{' '}
                            Host1:{' '}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host2 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{' '}
                            Host2:{' '}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                        {item.host3 && (
                          <h5 className="preview-date">
                            <FontAwesomeIcon className="" icon={faUser} />{' '}
                            Host3:{' '}
                            <span className="fw-normal">{item.user_name}</span>
                          </h5>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-4 preview-data-border d-lg-flex align-items-center">
                <div className="preview-date-main no-border-bottom">
                  <h4 className="date-txt notes">Details</h4>
                  <h6 className="preview-time notes-txt">
                    {getEventById?.payload?.message[0]?.event?.notes !== ''
                      ? getEventById?.payload?.message[0]?.event?.notes
                      : '-'}
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            // <Spin className="m-auto d-table" indicator={antIcon} />
            <div className="event-detail-skeleton">
              <Skeleton
                avatar={{
                  size: 'large',
                  shape: 'square',
                }}
                active
                paragraph={{
                  rows: 2,
                }}
              />
            </div>
          )}
        </div>

        <Modal
          className="modal-generic modal-500"
          centered
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Cancel
        //   </Button>,
        //   <Button key="submit" type="primary"
        //     className=""
        //     // onClick={handleOk}
        //     onClick={{}}
        //     >
        //     Next
        //   </Button>,
        // ]}
        >
          <h2 className="modal-hd1">Share</h2>
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-generic modal-500"
          centered
          visible={urlModal}
          onOk={() => setUrlModal(false)}
          onCancel={() => setUrlModal(false)}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
        >
          <h2 className="modal-hd1">Share</h2>
          <div class="your-copy-link">
            <div class="item-a">
              <a
                target="_blank"
                rel="noreferrer"
                href={shareUrls?.payload?.message}
              >
                {shareUrls?.payload?.message}
              </a>
            </div>
            <div class="item-b">
              {/* <div class="item-b copied"> */}
              <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
                Copy
              </Button>
            </div>
          </div>
        </Modal>

        <div className="control-boxes-main">
          <div className="control-boxes-box" ref={chatRef}>
            {/* <div className="live-stats-main">
              <div className="live-stats-box">
                <div className="stats-icon">
                  <FontAwesomeIcon className="text-info " icon={faUsers} />
                </div>
                <div className="stats-value">-</div>
                <div className="stats-hd">Viewers</div>
              </div>
              <div className="live-stats-box">
                <div className="stats-icon">
                  <FontAwesomeIcon className="text-danger " icon={faHeart} />
                </div>
                <div className="stats-value">-</div>
                <div className="stats-hd">Likes</div>
              </div>
              <div className="live-stats-box">
                <div className="stats-icon tag">
                  <FontAwesomeIcon className="" icon={faTag} />
                </div>
                <div className="stats-value">-</div>
                <div className="stats-hd product-view-hd">Product Views</div>
              </div>
              <div className="live-stats-box">
                <div className="stats-icon">
                  <FontAwesomeIcon className="text-danger" icon={faBoxOpen} />
                </div>
                <div className="stats-value">-</div>
                <div className="stats-hd">Orders</div>
              </div>
              <div className="live-stats-box">
                <div className="stats-icon usd-txt">USD</div>
                <div className="stats-value">0</div>
                <div className="stats-hd">Sales</div>
              </div>
            </div> */}
            {producer ? (
              <>
                {meeting && meetingData.length > 0 ? (
                  // <MixerScreen
                  //   endMeetingFlag={endMeetingFlag}
                  //   live={live}
                  //   onGolive={onGolive}
                  //   endMeeting={endMeeting}
                  //   meetingData={meetingData}
                  // />
                  <ProducerMixerScreen
                    channel={
                      {
                        rtmp: getBroadcastClient?.payload?.message?.ingestEndpoint,
                        key: getBroadcastClient?.payload?.message?.streamKey
                      }
                    }
                    endMeetingFlag={endMeetingFlag}
                    live={live}
                    onGolive={onGolive}
                    stopLiveEvent={() => dispatch(stopLive(id))}
                    endMeeting={endMeeting}
                    meetingData={meetingData}
                  />
                ) : (
                  <StartMeetingScreen setMeeting={setMeeting} id={id} startLoading={startLoading} />
                )}
              </>
            ) : (
              <>
                <MixerScreen
                  endMeetingFlag={endMeetingFlag}
                  host={true}
                  meetingData={meetingData}
                  checkEvent={() => {
                    dispatch(getEventsBy_Id(id)).then((res) => {
                      if (res.payload.success) {
                        setProducer(res.payload?.message[0]?.producer);
                        if (res.payload?.message[0]?.meeting_info) {
                          setMeetingData(res.payload?.message);
                          setMeeting(true);
                        } else {
                          setMeeting(false);
                        }
                        setEventEnded(res.payload?.message);
                        setBanner(res.payload?.message[0]?.event?.banner);
                        setDate(res.payload?.message[0]?.event?.start_date);
                        setTitle(res.payload?.message[0]?.event?.title);
                        setSpin(false);
                      }
                    });
                  }}
                />
                {/* {meetingData ? <MixerScreen host={true} meetingData={meetingData} /> : <EventTimer checkEvent={() => {
                    dispatch(getEventsBy_Id(id)).then((res) => {
                      if (res.payload.success) {
                        setProducer(res.payload?.message[0]?.producer)
                        if (res.payload?.message[0]?.meeting_info) {
                          setMeetingData(res.payload?.message);
                          setMeeting(true)
                        } else {
                          setMeeting(false)
                        }
                        setEventEnded(res.payload?.message);
                        setBanner(res.payload?.message[0]?.event?.banner);
                        setDate(res.payload?.message[0]?.event?.start_date);
                        setTitle(res.payload?.message[0]?.event?.title);
                        setSpin(false);
                      }
                    });
                  }} />} */}
              </>
            )}





          </div>



          <div
            className="control-boxes-box tabs-control-room-main"
          // style={{ height: height }}
          >
            <Tabs
              className="tabs-control-room chat-tab-data"
              defaultActiveKey="3"
              onChange={onKeyChange}
            >
              <TabPane
                // tab="Chat"
                key="1"
                // tab={
                //   <Tooltip
                //     title={
                //       live === 'live' || meetingData[0]?.event_status === 'live'
                //         ? ''
                //         : producer
                //         ? 'Go live to interact with your audience!'
                //         : 'Start Hosting to interact with your audience!'
                //     }
                //   >
                //     <span>Chat</span>
                //   </Tooltip>
                // }
                // disabled={
                //   live === 'live' || meetingData[0]?.event_status === 'live'
                //     ? false
                //     : true
                // }
                // className={`${
                //   live === 'live' || meetingData[0]?.event_status === 'live'
                //     ? ''
                //     : 'chat-tab-txt'
                // }`}
                tab={
                  <Tooltip
                    title={
                      meeting && meetingData.length > 0
                        ? ''
                        : producer
                          ? 'Start Meeting to interact with your audience!'
                          : 'Start Hosting to interact with your audience!'
                    }
                  >
                    <span>Chat</span>
                  </Tooltip>
                }
                disabled={meeting && meetingData.length > 0 ? false : true}
                className={`${meeting && meetingData.length > 0 ? '' : 'chat-tab-txt'
                  }`}
              >
                <Chat
                  eventId={id}
                  chat={chat}
                  meeting={meeting}
                  height={height}
                />
                {/* {meeting && meetingData.length > 0 ? (
                  <Chat />
                ) : (
                  <div className="upcoming-box-main no-bg">
                    <p className="upcoming-para">
                      {producer
                        ? 'Go live to interact with your audience!'
                        : 'Event not started by producer'}
                    </p>
                  </div>
                )} */}
              </TabPane>
              {/* <TabPane tab="Poll" key="2">
                    <h4 className="hd-tab">Upcomings</h4>
                    <div className="upcoming-box-main">
                      <div className="upcoming-img">
                        <img className="img-fluid" src={live2} alt="live2" />
                      </div>
                      <p className="upcoming-para">
                        {" "}
                        You didn't create poll for this event, yet.
                      </p>
                      <Link
                        to="#"
                        className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="nav-text">Create</span>
                      </Link>
                    </div>
                  </TabPane> */}
              <TabPane tab="Products" key="3">
                {/* <h4 className="hd-tab">Products Listing</h4> */}
                <div
                  className={`control-products-listing-main-wrapper scrollbar-style ${getEventById?.payload?.message[0]?.event?.products.length >
                    4
                    ? 'overflow-y-scroll'
                    : ''
                    }`}
                >
                  {getEventById?.payload?.message[0]?.event?.products.length ? (
                    getEventById?.payload?.message[0]?.event?.products.map(
                      (item, i) => {
                        return (
                          <>
                            <div className="control-products-listing-main ">
                              <div className="control-products-listing">
                                <div className="control-product-image">
                                  <img
                                    src={item.mediaUrl}
                                    alt={item.title}
                                    className=""
                                  />
                                </div>

                                <div className="control-product-content">
                                  <div className="product-left">
                                    <div className="control-product-title">
                                      {item.title}
                                    </div>
                                    <div className="control-product-price">
                                      {item.price}
                                    </div>
                                  </div>
                                  <div className="product-right">
                                    <div className="product-quantity">
                                      {/* Qty: 150 */}{' '}
                                    </div>
                                    <div className="control-product-copy">
                                      <button
                                        class="default-btn small-btn fw-normal"
                                        onClick={() =>
                                          productUrl(item.ProductUrl)
                                        }
                                      >
                                        Copy Url
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <Link to="#" className="block-link"></Link> */}
                            </div>
                          </>
                        );
                      }
                    )
                  ) : (
                    // "No Product"
                    <Skeleton
                      avatar={{
                        size: 'large',
                        shape: 'circle',
                      }}
                      active
                      paragraph={{
                        rows: 2,
                      }}
                    />
                  )}
                </div>
              </TabPane>
              {/* <TabPane tab=" Announcement " key="4">
                <h4 className="hd-tab">Upcomings</h4>
                <div className="upcoming-box-main">
                  <div className="upcoming-img">
                    <img className="img-fluid" src={live2} alt="live2" />
                  </div>
                  <p className="upcoming-para">
                    {' '}
                    You didn't create annoucement for this event, yet.
                  </p>
                  <Link
                    to="#"
                    className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span className="nav-text ">Create</span>
                  </Link>
                </div>
              </TabPane> */}
            </Tabs>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
export default connect(null, chatActions)(ControlRoom);
