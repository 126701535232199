import { MY_PROFILE } from "../../types/types";
export default function getMyProfileData(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case MY_PROFILE:
            return {
                ...state,
                payload: payload,
            };
        default:
            return state;
    }
}