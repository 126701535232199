import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import {
  getEvents,
} from '../../redux/actions/event.action';
import { useDispatch } from 'react-redux';
import '../../css/postingContent.scss';
import defaultImg from '../../images/banner.jpg';
import Swal from "sweetalert2";
import * as schedulePostActions from "../../redux/actions/schedulePost";
import * as instaAction from "../../redux/actions/instaPost";
import { connect } from "react-redux";
import {
  Modal,
  notification,
  Spin, Button
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReactPaginate from "react-paginate";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

function PostingContentGallery({
  directPublish,
  directPublishFB,
  getMedia,
  gallery,
  deleteMedia,
  title
}) {
  const [event, setEvent] = useState("");
  const [loading, setLoading] = useState(true);
  const [publishLoading, setPublishLoading] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [currentDataFB, setCurrentDataFB] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFB, setIsModalOpenFB] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [caption, setCaption] = useState("");
  const [captionFB, setCaptionFB] = useState("");


  const limit = 8;
  // const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    getMedia("active").then(() => setLoading(false));
  }, [title]);

  function onPublishIG(seletedItem) {
    setCurrentData(seletedItem);
    setIsModalOpen(true);
  }

  function onPublishFB(seletedItem) {
    setCurrentDataFB(seletedItem);
    setIsModalOpenFB(true);
  }

  const handleOk = () => {
    setPublishLoading(true);
    directPublish({
      media_url: currentData?.media_url,
      // media_library_id: currentData?.event?.media_library_id,
      // media_id: currentData?.event?.media_id,
      caption: caption ? caption : currentData?.title,
    })
      .then(() => {
        setPublishLoading(false);
        setIsModalOpen(false);
        setCurrentData({})
        notification.success({
          message: "Successfully Published on Instagram",
          className: 'toast-success',
        });
      })
      .catch((err) => {
        setPublishLoading(false);
        notification.error({
          message: err.response.data.message,
          className: 'toast-error',
        });
      });

  };

  const handleOkFB = () => {
    setPublishLoading(true);
    directPublishFB({
      media_url: currentDataFB?.media_url,
      // media_library_id: currentData?.event?.media_library_id,
      // media_id: currentData?.event?.media_id,
      message: captionFB ? captionFB : currentDataFB?.title,
    })
      .then(() => {
        setPublishLoading(false);
        setIsModalOpenFB(false);
        setCurrentDataFB({})
        notification.success({
          message: "Successfully Published on Facebook",
          className: 'toast-success',
        });
      })
      .catch((err) => {
        setPublishLoading(false);
        notification.error({
          message: err.response.data.message,
          className: 'toast-error',
        });
      });

  };

  const handleCancel = () => {
    setCurrentData({})
    setIsModalOpen(false);
    setIsModalOpenFB(false);
    setCaption("")
  };
  const handleCancelFB = () => {
    setCurrentDataFB({})
    setIsModalOpenFB(false);
    setCaption("")
  };

  const onDelete = async (item) => {
    let page = gallery.data.length === 1 ? currentPage : currentPage + 1;

    if (currentPage === 0) page = 1;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMedia(item.media_library_id).then(() => {
          // getMedia("active");
          setLoading(true)
          getMedia("active", page).then(() => setLoading(false));
          notification.success({
            message: "Successfully deleted",
            className: 'toast-success',
          });
          // setDeleteLoading(false);
          if (gallery.data.length === 1) {
            setCurrentPage(currentPage - 1);
          }
          // toast.error("successfully deleted")
        });
      }
    });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getMedia("active", page + 1, limit).then(() => setLoading(false));
  };

  const handleCaption = (e) => {
    setCaption(e.target.value)
  };
  const handleCaptionFB = (e) => {
    setCaptionFB(e.target.value)
  };

  return (
    <>
      {/* <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center"> */}
      <div className="container-fluid">

        <div className="title-area mt-4">
          <h1>{title === "gallery" && "Media Gallery"}</h1>
        </div>


        {!loading ? (
          <Row className="post-analytics-tab-boxes-ift">
            {gallery.data.length > 0 ? (
              gallery.data.map((item, i) => (
                <Col xs={12} xl={3} md={6}>
                  <div className={`card any_bx analytic-box pb-0`}>
                    <div className="camp-row row">
                      <div className="campaign-header col-12">
                        <h6>
                          {item.title.length > 20
                            ? item.title.slice(0, 20) + "..."
                            : item.title}
                        </h6>
                      </div>
                      <div
                        className="any-post-img-col col-12"
                        style={{ marginBottom: 50 }}
                      >
                        <div className="any-post-image media-post-new">
                          <div className="any-image-box">
                            <div className="any-image-box-iner posting-image">
                              {new URL(item?.media_url).pathname.split(".")[1] ===
                                "mp4" ? (
                                <>
                                  {/* <button
                                  onClick={(e) =>
                                    Pauseplay(e, item.media_library_id)
                                  }
                                  className="btn-link btn-play"
                                >
                                  {videoId === item.media_library_id ? (
                                    !videoIcon ? (
                                      <i
                                        class="fa fa-play"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <i
                                        class="fa fa-pause"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  ) : (
                                    <i
                                      class="fa fa-play"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </button> */}
                                  <video
                                    className="gallery-video media-image"
                                    // controlsList="nodownload"
                                    controls={true}
                                    id={item.media_library_id}
                                    alt={item.title}
                                  >
                                    <source
                                      src={item.media_url}
                                      type="video/mp4"
                                    ></source>
                                  </video>
                                </>
                              ) : (
                                <img
                                  src={item.media_url}
                                  className="img-fluid media-image"
                                  alt={item.title}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cam-buttons col-12">


                      <button className="btn bg-primary-color"
                        onClick={() => onPublishIG(item)}
                      >
                        <i className="fa fa-instagram" /> Publish on IG
                      </button>
                      <button className="btn bg-primary-color"
                        onClick={() => onPublishFB(item)}
                      >
                        <i className="fa fa-facebook" /> Publish on Fb
                      </button>
                      <button
                        className="btn bg-primary-color"
                        onClick={() => {
                          onDelete(item);
                        }}
                      >
                        <i className="fa fa-trash" /> Delete
                      </button>

                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="col-md-12">
                No Data
              </div>
            )}
          </Row>

        ) :
          <span className="spinclass loader-center position-relative">
            <Spin size="large" />
          </span>
        }
        {gallery?.data?.length > 0 && !loading && (
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            pageClassName="page-item "
            pageLinkClassName="page-link custom-paginate-link btn btn-primary"
            previousClassName="page-item"
            previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
            nextClassName="page-item"
            nextLinkClassName="page-link custom-paginate-next btn btn-primary"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={currentPage}
            pageCount={Math.ceil(gallery.total_count / limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
            onPageChange={handlePageClick}
            containerClassName={
              "pagination justify-content-center mt-2 custom-paginate"
            }
            // subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}
        {isModalOpen &&
          <Modal
            className="modal-generic modal-600"
            centered
            visible={isModalOpen}
            // onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            // footer={true}
            maskClosable={false}
            keyboard={false}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary"
                className=""
                onClick={handleOk}
                loading={publishLoading}
              >
                Publish
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Publish Post On Instagram</h2>
            <div class="modal-main row">
              <div className="publishdata-left col-md-6 mb-30">
                <div className='post-modal-image'>

                  {new URL(currentData?.media_url).pathname.split(".")[1] ===
                    "mp4" ? (
                    <>
                      <video
                        className="post-image-left"
                        // controlsList="nodownload"
                        controls={true}
                        id={currentData?.media_library_id}
                        alt={currentData?.title}
                      >
                        <source
                          src={currentData?.media_url}
                          type="video/mp4"
                        ></source>
                      </video>
                    </>
                  ) : (
                    <img
                      src={currentData?.media_url}
                      className="post-image-left"
                      alt={currentData?.title}
                    />
                  )}
                </div>
              </div>
              <div className="publishdata-right col-md-6">
                <div class="publish-post-detail">
                  <h4 class="count-title">Name</h4>
                  <h3 class="count">
                    {currentData?.title}
                  </h3>
                </div>
                {/* <div class="publish-post-detail">
              <h4 class="count-title">Start Date</h4>
              <h3 class="count">
              {moment(currentData?.event?.start_date)
                .utc()
                .format('YYYY-MM-DD hh:mm A')}
                </h3>
            </div> */}


                <div className="mb-0">
                  <label>Enter Caption</label>
                  <textarea
                    name="caption"
                    placeholder="Enter Caption"
                    onInput={handleCaption}
                    className="form-control"
                    rows="3"
                    value={caption}
                  />
                </div>


                {/* <button className='default-btn medium-btn padb-3 d-table m-auto'>
              Share
            </button> */}

              </div>
            </div>
          </Modal>
        }
        {isModalOpenFB &&
          <Modal
            className="modal-generic modal-600"
            centered
            visible={isModalOpenFB}
            // onOk={handleOk}
            onCancel={handleCancelFB}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            // footer={true}
            maskClosable={false}
            keyboard={false}
            footer={[
              <Button key="back" onClick={handleCancelFB}>
                Cancel
              </Button>,
              <Button key="submit" type="primary"
                className=""
                onClick={handleOkFB}
                loading={publishLoading}
              >
                Publish
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Publish Post On Facebook</h2>
            <div class="modal-main row">
              <div className="publishdata-left col-md-6 mb-30">
                <div className='post-modal-image'>

                  {new URL(currentDataFB?.media_url).pathname.split(".")[1] ===
                    "mp4" ? (
                    <>
                      <video
                        className="post-image-left"
                        // controlsList="nodownload"
                        controls={true}
                        id={currentDataFB?.media_library_id}
                        alt={currentDataFB?.title}
                      >
                        <source
                          src={currentDataFB?.media_url}
                          type="video/mp4"
                        ></source>
                      </video>
                    </>
                  ) : (
                    <img
                      src={currentDataFB?.media_url}
                      className="post-image-left"
                      alt={currentDataFB?.title}
                    />
                  )}
                </div>
              </div>
              <div className="publishdata-right col-md-6">
                <div class="publish-post-detail">
                  <h4 class="count-title">Name</h4>
                  <h3 class="count">
                    {currentDataFB?.title}
                  </h3>
                </div>

                <div className="mb-0">
                  <label>Enter Caption</label>
                  <textarea
                    name="caption"
                    placeholder="Enter Caption"
                    onInput={handleCaptionFB}
                    className="form-control"
                    rows="3"
                    value={captionFB}
                  />
                </div>


                {/* <button className='default-btn medium-btn padb-3 d-table m-auto'>
              Share
            </button> */}

              </div>
            </div>
          </Modal>
        }
      </div>
    </>
  );
}

function mapStateProps({ gallery }) {
  return { gallery };
}
export default connect(mapStateProps, {
  ...instaAction,
  ...schedulePostActions,
})(PostingContentGallery);
