import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as bioPostAct from "../../../redux/actions/bioPost";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Swal from "sweetalert2";
import axios from "axios";
// import { toast } from "react-toastify";
import { Row } from "react-bootstrap";
import Select from "react-select";
import "../../../css/bioshop.scss";
import { notification,Spin } from 'antd';

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
const userInfo_token = JSON.parse(localStorage.getItem("userInfo"));
function BioShopPost({
  getNewBioPost,
  bioPosts,
  id,
  selectPost,
  clearNewBioPost,
}) {
  const [sort, setSort] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Pid, setPid] = useState();
  const [savePost, setSavePost] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [bioShopLimit, setBioShopLimit] = useState({
    value: 100,
    label: "100",
  });

  const [videoIcon, setVideoIcon] = useState(false);
  const [videoId, setVideoId] = useState();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")).pid;
    getNewBioPost(1, null, clearNewBioPost, 100, userInfo).then(() =>
      setLoading(false)
    );
    document.body.classList.add("bioshop-body");
  }, []);

  useEffect(() => {
    setSavePost(bioPosts.data);
  }, [bioPosts]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      height: '30px',
      boxShadow: 'none',
      // borderRadius: '0px',
      border: '1px solid black',
      '&:hover': {
        border: '1px solid black',
      },
    }),
  };


  // function Pauseplay(e, id) {
  //   e.preventDefault();
  //   // setVideoId(id);

  //   var testvideo = document.getElementById(id);
  //   console.log(testvideo?.paused, "testvideo");

  //   if (testvideo?.paused) {
  //     testvideo.play();
  //     setVideoIcon(true);
  //   } else {
  //     testvideo.pause();
  //     setVideoIcon(false);
  //   }
  // }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSavePost(arrayMove(savePost, oldIndex, newIndex));
    setSort(true);

  };
  const sortBioShop = () => {
  
    Swal.fire({
      title: `Are you sure you want Save BioShop?`,

      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
            headers: { Authorization: `Bearer ${userInfo?.token}` }
          };
        setSortLoading(true);
        axios
          .post(`https://apiv2.konnect.bio/v1/users/revise/sortbioshopposts`, { posts: savePost},config)
          .then((response) => {
            setSortLoading(false);
            // toast.success(response.data.message);
            // toast.success("Save BioShop Successfully");
            notification.success({
              message: 'Save Store Successfully',
              className: 'toast-success',
            });
          })
          .catch((err) => {
            // toast.error(err.response.data.message);
            notification.error({
              message: 'Something went wrong!!',
              className: 'toast-error',
            });
            // toast.error("Something went wrong!!", {
            //   autoClose: false,
            // });
            setSortLoading(false);
          });
      } else {
        setSortLoading(false);
      }
    });
  };

  const bioshopOptions = [
    { value: 100, label: "100" },
    { value: 300, label: "300" },
    { value: 500, label: "500" },
  ];

  const handleBioShopLimit = (e, options) => {
    setLoading(true);
    setBioShopLimit(options);
    const userInfo = JSON.parse(localStorage.getItem("userInfo")).pid;
    getNewBioPost(1, null, clearNewBioPost, options.value, userInfo).then(() =>
      setLoading(false)
    );
  };

const SortableItem = SortableElement((props) => {
    const { value: value } = props;

    return (
      <div className="image-post-box-aff" key={value.post_id}>
        <div className="image-post-box-aff-inr">
          <div className="image-post-box-aff-inr-inr media-post-new">
            {value.media_type === "VIDEO" ? (
              // <video
              //   id={`post-video-${value.post_id}`}
              //   //autoPlay
              //   controls={false}
              //   controlsList="nodownload"
              // >
              //   <source
              //     src={value.media_url + "#t=0.001"}
              //     type="video/mp4"
              //   ></source>
              // </video>
              <>
                {/* <button
                  // onClick={(e) => Pauseplay(e, value.post_id)}
                  className="btn-link btn-play"
                >
                  {videoId === value.post_id ? (
                    !videoIcon ? (
                      <i class="fa fa-play" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-pause" aria-hidden="true"></i>
                    )
                  ) : (
                    <i class="fa fa-play" aria-hidden="true"></i>
                  )}
                </button> */}
                <video
                  // className="gallery-video media-image"
                  // controlsList="nodownload"
                  controls={true}
                  id={value.post_id}
                  alt={value.title}
                  className="post-image"
                >
                  <source
                    src={value.media_url}
                    // src={value.media_url + "#t=0.9"}
                    type="video/mp4"
                  ></source>
                </video>
              </>
            ) : (
              <img
                src={value.media_url}
                alt="post-img"
                className="post-image"
                style={{
                  width: "100%",
                  // height: "auto",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer((props) => {
    const { items, ...restProps } = props;
    return (
      <div className="bioshop-area row">
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item.post_id}`}
            index={index}
            value={item}
            {...restProps}
          />
        ))}
      </div>
    );
  });
  return (
    <React.Fragment>
     
      <div className="bioshop_container_main">
        <Row className="bioshop-title">
          <div className="col-sm-6 col-12">
          <div class="title-area">
        <h1 class="page-title">Reorder Social Store
        <small class="text-muted ml-2">(Sort Social Store)</small>
          </h1>
      </div>
            {/* <h4 className="page-title">
              Reorder Social Store
              <small class="text-muted ml-2">(Sort Social Store)</small>
            </h4> */}

<div className="bioshopaction">
            <div className="bioshoplimit">
              <Select
                name="post"
                className="selectCustomization select-style dark-field"
                options={bioshopOptions}
                placeholder="Select Bioshop"
                styles={style}
                onChange={(options, e) => handleBioShopLimit(e, options)}
                value={{
                  value: bioShopLimit.value,
                  label: "Limit - " + bioShopLimit.label,
                }}
                // isDisabled={
                //   sortLoading || loading || bioPosts.next?.page ? false : true
                // }
              />
            </div>
            <div className="syncbutton">
              <button
                id="sort-button"
                className={`default-btn fw-normal medium-btn h-38 btn-sm mr-0 ${
                  showTopBtn ? "icon-position" : ""
                }`}
                onClick={() => sortBioShop()}
                disabled={sortLoading || loading}
              >
                Save
              </button>
              {sortLoading && (
                <div class="sync_loading">
                  <span className="loading_text">
                    Please Wait
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </span>
                </div>
              )}
            </div>
          </div>
          
          </div>
          {/* <div className="col-sm-6 col-12 bioshopaction"> */}
        
        </Row>
        <Row>
          <div className="col-md-12">
          
          {loading && (
          <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                      </span>
                      )}
                      
            {!loading ? (
              savePost.length > 0 ? (
                <SortableList
                  // shouldUseDragHandle={true}
                  // useDragHandle
                  axis="xy"
                  items={savePost}
                  onSortEnd={onSortEnd}
                  lockToContainerEdges={true}
                  lockOffset="50%"
                  // distance={1}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 100,
                  }}
                >
                  <h4>Not Found</h4>
                </div>
              )
            ) : (
              <>
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 5,
                      height: 300,
                    }}
                  >
                  
                  </div>
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
     
    </React.Fragment>
  );
}

function mapStateToProps({ bioPosts }) {
  return { bioPosts };
}
export default connect(mapStateToProps, bioPostAct)(BioShopPost);
