import React, { useEffect, useState, useRef } from "react";
import VideoShop from "./videoShop";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment";
import { Select } from "antd";
// import InputValidation from "../../../../components/InputValidation/InputValidation";
import Formsy from "formsy-react";
import "antd/dist/antd.css";
import * as promo from "../../../../redux/actions/promoRequest";
// import PermissionHelper from "../../../../components/PermissionHelper";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "axios";
import numeral from "numeral";
import ImageShop from "./imageShop";
import { DatePicker, Spin, Form } from "antd";
import { Row,  Col, Spinner } from 'react-bootstrap';
import subCategories from "./subCategory";
import SubCategory from "./subCategory";
import { toast } from "react-toastify";
import { BASEURL } from "../../../../config";

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

let userInfo;
let dataPromo;
let PassPromoCode;
var subPromo;
var subDiscount;
var promoList;
var imgDataSet = [];
var SubCategoryId;
userInfo = JSON.parse(localStorage.getItem("userInfo"));
function ShopRightBar(props, { getPromoRequest, promoRequest, PromoPayload }) {

  if (typeof props.promo == "object" && props.promo !== null) {
  } else {
    subPromo = props.promo;
    subDiscount = props.discount;
  }
  const media_id = props.singlePost.post_id;
  const [startDate, setStartDate] = useState("");
  const [connNotFound, setconnFound] = useState(true);
  const [endDate, setEndDate] = useState("");
  const [redirectedUrl, setRedirectedUrl] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const formRef = useRef("LinkForm");
  const [promoCode, setPromoCode] = useState("");
  const [loader, setLoader] = useState(true);
  const [Kbfee, setKbfee] = useState();
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [imgShop, setImgshop] = useState();
  const [promoCodeDscs, setDsc] = useState();
  const [promoCodePromo, setPromo] = useState();
  const [promoLoading, setPromoLoading] = useState(false);
  const [source, setSource] = useState(props.product_source);
  const [selectOption, setOption] = useState(false);
  const [url, setUrl] = useState("");
  const [showVideo, setVideo] = useState(false);

  useEffect(() => {
    fetchPromo();
  }, []);
  useEffect(() => {
    if (props?.userInfo?.account_type === "brand") {
      setSource("ecommerce");
    } else {
      setSource("other");
    }
  }, [props.userInfo]);

  const fetchPromo = async (media_id) => {
    setPromoLoading(true);
    await axios
      .get(`${BASEURL}affiliate/getdefaultpromo`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((response) => {
        setPromo(response?.data?.promo);
        setDsc(response?.data?.discount);
        setPromoLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setPromoLoading(false);
      });
  };

  useEffect(() => {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // axios
    //   .post("/fee")
    //   .then((res) => {
    //     setKbfee(res.data.message);
    //   })
    //   .catch((res) => {});
    axios
      .get(`${BASEURL}affiliate/getcontract`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((res) => {
        setKbfee(res.data?.message?.fee ? res.data?.message?.fee : "0");
      })
      .catch((res) => { });
  }, []);

  useEffect(() => {
    if (props.singlePost.linked == false) {
      fetchPromo();
    }
  }, [props.singlePost.linked]);

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    setDsc(props.discount);
  }, [props.discount]);

  // useEffect(() => {
  //   if (props.category.length == 0) {
  //     setSource("ecommerce");
  //   }
  // }, [props.category]);

  useEffect(() => {
    setPromo(props.promo);
  }, [props.promo]);

  useEffect(() => {
    // if (props.singlePost.linked) {
    //   setSource(props.product_source);
    // } else {
    //   setSource("ecommerce");
    // }
    if (connNotFound) {
      props.singlePost.linked
        ? setSource(props.product_source)
        : setSource("ecommerce");
    } else {
      setSource("other");
    }
  }, [props.product_source, props.singlePost]);

  useEffect(() => {
    setAmount(props.amount);
  }, [props.amount]);

  useEffect(() => {
    setDescription(props.description);
  }, [props.description]);

  useEffect(() => {
    setRedirectedUrl(props.redirectedUrl);
  }, [props.redirectedUrl]);

  useEffect(() => {
    props.selectPost(false, "");
    props.closeModel(false);
  }, [props.mobileDropdown]);

  useEffect(() => {
    if (props?.userInfo?.account_type === "brand") {
      setLoader(false);
      props
        .getPromoRequest()
        .then((res) => {
          setLoader(true);
        })
        .catch((res) => {
          setconnFound(false);
          setSource("other");
        });
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (props.promo == "" && props.discount == "") {
    }
    if (props.redirectedUrl !== "" && props.singlePost.linked) {
      // setDsc(props.discount);
      // setPromo(props.promo);
      // setDescription(props.description);
      // setAmount(props.amount);
    } else {
      if (props.autoFocus === true) {
        // console.log("----");
        fetchPromo();
        setDescription("");
        setAmount(0);
      }
    }
  }, [props.autoFocus]);

  // useEffect(() => {
  //   if (typeof props.promo == "object" && props.promo !== null) {
  //   } else {
  //     if (props.promo) {
  //       setDsc(props.discount);
  //       setPromo(props.promo);
  //     }
  //     if (props.redirectedUrl === "") {
  //       setDsc(default_discount);
  //       setPromo(default_promo);
  //       setDescription("");
  //       setAmount(0);
  //       console.log(default_discount + "-without-" + default_promo);
  //     } else {
  //       setDsc(props.discount);
  //       setPromo(props.promo);
  //       console.log(promoCode + "-with-" + promoCodeDscs);
  //     }
  //   }
  // }, [props.redirectedUrl]);

  // useEffect(() => {
  //   if (props.promoData.promo === undefined) {
  //   } else {
  //     setPromo(props.promoData.promo);
  //     setDsc(props.promoData.discount);
  //   }
  // }, [props.promoData.promo]);

  if (loader == true) {
    dataPromo = props.promoRequest.message;

    const promo = dataPromo;

    if (dataPromo != undefined) {
      promoList = dataPromo;
    } else {
      promoList = [];
    }
  }

  const AddOption = () => {
    setOption(true);
  };

  function dateRangePickerChanger(value, dataString) {
    let startDate = dataString[0];
    let endDate = dataString[1];

    props.dateRange(startDate, endDate);
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  };
  const changeDescription = (e) => {
    setDescription(e.target.value);
  };
  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
      // setDsc("0%");
      // setPromo("KB0");
    } else {
      var values = e.value.split(" ");
      var discount = values[0];

      setDsc(discount);
      setPromo(e.children);
    }
  };
  const imgData = (data) => {
    imgDataSet = data;
    setVideo(false);
    if (imgDataSet?.length > 0) {
      setOption(false);
      setVideo(true);
      setUrl(imgDataSet[0]?.ProductUrl);
    } else {
      setOption(false);
    }
  };

  const categoryData = (data) => {
    SubCategoryId = data;
  };
  const copyToClipboard = (url) => {
    let textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toast.success("Copied to Clipboard!");
  };
  // };
  return (
    <>
      {props.startDate && props.endDate && (
        <Form
          onValidSubmit={() => {
            if (props.updatePage) {
              props.updatePost();
            } else {
              props.savePost &&
                props.savePost(
                  this,
                  promoCodePromo,
                  promoCodeDscs,
                  description,
                  amount,
                  imgDataSet,
                  source,
                  SubCategoryId,
                  props.singlePost.media_type
                );
            }
          }}
          ref={formRef}
        >
          <div
            className={`image-edit-box ${props.isSelectPost ? "show" : "hidden"
              }`}
          >
            <span
              onClick={() => props.selectPost(false, "")}
              className="fa fa-times ift-cancel"
            ></span>
            <div className="ind-post-anlytics image-box-info">
              <div className="edit-left">
                <h4>
                  {" "}
                  {/* {props.singlePost.media_type === "VIDEO" ? (
                    "Add Video"
                  ) : (
                    <> */}
                  {props.singlePost.linked || props.updatePage
                    ? props.singlePost.media_type === "VIDEO"
                      ? "Edit Video"
                      : "Edit Post"
                    : props.singlePost.media_type === "VIDEO"
                      ? "Add Video"
                      : "Add Post"}
                  {/* </>
                  )} */}
                </h4>
                <p>
                  {props.singlePost.linked || props.updatePage
                    ? "Updated on " +
                    moment.utc(props.updatedDate).format("MMM Do YYYY")
                    : "Posted on " +
                    moment
                      .utc(props.singlePost.timestamp)
                      .format("MMM Do YYYY")}

                  {/* {props.media_id ? (
                    props.singlePost.linked || props.updatePage ? (
                      <span className="date-loader">
                        <Loader />
                      </span>
                    ) : (
                      "Updated on " +
                      moment.utc(props.updatedDate).format("MMM Do YYYY")
                    )
                  ) : (
                    "Posted on " +
                    moment.utc(props.singlePost.timestamp).format("MMM Do YYYY")
                  )} */}
                </p>
              </div>

              {media_id && (
                <>
                  <div className="edit-right">
                    <div className="an-col col-md-3">
                      <div className="an-col-inr">
                        <div className="an-content clearfix">
                          <span class="dash_icon-top">
                            <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
                          </span>
                          <div class="imp-t text-right">
                            {props.fetchUserPost.post_views
                              ? props.fetchUserPost.post_views
                              : 0}
                          </div>
                          <div class="imp-tx text-uppercase text-muted text-right">
                            IMPRESSIONS
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="an-col col-md-3">
                      <div className="an-col-inr">
                        <div className="an-content clearfix">
                          <span class="dash_icon-top">
                            <i
                              class="fa fa-hand-pointer-o fa-2x"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <div class="imp-t text-right">
                            {props.fetchUserPost.post_clicks
                              ? props.fetchUserPost.post_clicks
                              : 0}
                          </div>
                          <div class="imp-tx text-uppercase text-muted text-right">
                            CLICKS
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="an-col col-md-3">
                      <div className="an-col-inr">
                        <div className="an-content clearfix">
                          <span class="dash_icon-top">
                            <i
                              class="fa fa-handshake-o fa-2x"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <div class="imp-t text-right">
                            {props.fetchUserPost.ctr
                              ? props.fetchUserPost.ctr === "NaN"
                                ? 0
                                : props.fetchUserPost.ctr
                              : 0}
                            %
                          </div>
                          <div class="imp-tx text-uppercase text-muted text-right">
                            ENGAGEMENT
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="an-col col-md-3">
                      <div className="an-col-inr">
                        <div className="an-content clearfix">
                          <span class="dash_icon-top">
                            <i class="fa fa-usd fa-2x" aria-hidden="true"></i>
                          </span>
                          <div class="imp-t text-right">$0.00</div>
                          <div class="imp-tx text-uppercase text-muted text-right">
                            REVENUE
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="image-wrapper">
              <div className="image-box">
                {!props.singleLoading ? (
                  props.singlePost.media_type !== "VIDEO" &&
                  // <img src={`${props.singlePost.media_url}`} alt="media_url" />
                  (userInfo?.account_type !== "influencer" ? (
                    <ImageShop
                      imgData={imgData}
                      children={props.children}
                      check={props.redirectedUrl}
                      mediaUrl={props.singlePost.media_url}
                      selectPost={props.singlePost.media_url}
                      categoryList={props.categories}
                      promoList={promoList}
                      promoLoading={promoLoading}
                      Kbfee={Kbfee}
                      category={props.category}
                      subcategory={props.selectSub}
                      source={source}
                      url={redirectedUrl}
                      promoCode={promoCodePromo}
                      promoDsc={promoCodeDscs}
                      // setSource={setSource}
                      updateProduct={props.singlePost.linked}
                      mediaType={props.singlePost.media_type}
                    />
                  ) : (
                    <img
                      src={`${props.singlePost.media_url}`}
                      alt="media_url"
                    />
                  ))
                ) : (
                  <>
                    {props.singlePost.media_type !== "VIDEO" ?
                       <span className="spinclass loader-center position-relative">
                       <Spin size="large" />
                   </span>
                      :
                      <></>
                    }
                  </>
                )}
                {props.singlePost.media_type === "VIDEO" && (
                  <VideoShop
                    src={props.singlePost.media_url}
                    sku={props.children[0]?.skuDataOther}
                    repeat={true}
                    length={imgDataSet}
                    option={selectOption}
                    imgData={imgData}
                    children={props.children}
                    check={props.redirectedUrl}
                    mediaUrl={props.singlePost.media_url}
                    selectPost={props.singlePost.media_url}
                    categoryList={props.categories}
                    promoList={promoList}
                    promoLoading={promoLoading}
                    Kbfee={Kbfee}
                    category={props.category}
                    subcategory={props.selectSub}
                    source={source}
                    url={redirectedUrl}
                    promoCode={promoCodePromo}
                    promoDsc={promoCodeDscs}
                    // setSource={setSource}
                    updateProduct={props.singlePost.linked}
                    mediaType={props.singlePost.media_type}
                  />
                )}
              </div>
              <div className="image-edit-links">
                <>
                  {userInfo?.account_type !== "influencer" ? (
                    <div className="">
                      <label>Select Destination</label>
                      <Select
                      size="large"
                        key={Date.now()}
                        value={source}
                        style={{ width: "100%" }}
                        placeholder="Select Destination"
                        onChange={(value) => setSource(value)}
                        className="source_cap"
                        disabled={
                          props.singlePost.linked ||
                            props.updatePage ||
                            !connNotFound
                            ? true
                            : false
                        }
                      >
                        {/* <Option className="source_cap" value={source}>
                        {source}
                      </Option> */}
                        {connNotFound ? (
                          props.singlePost.linked || props.updatePage ? (
                            <Option className="source_cap" value={source}>
                              {source === "ecommerce"
                                ? "Web Store"
                                : "Web Link"}
                            </Option>
                          ) : (
                            <>
                              <Option value="ecommerce">Web Store</Option>
                              <Option value="other">Web Link</Option>
                            </>
                          )
                        ) : (
                          <Option className="source_cap" value={source}>
                            {source === "ecommerce" ? "Web Store" : "Web Link"}
                          </Option>
                        )}
                        {/* <Option value="other">Others</Option> */}
                      </Select>
                    </div>
                  ) : null}
                </>

                {/* {userInfo?.account_type === "influencer" ? (
                  <div
                    className={
                      userInfo?.account_type === "influencer" ? "" : "mt-3"
                    }
                  >
                    <label>
                      URL/AFFILIATE LINK -{" "}
                      <a
                        onClick={() => {
                          Swal.fire({
                            title: "Note",
                            text: "You can add link of a website or affiliate link provided by an affiliate network, example: CJ, Rakuten, Amazon, etc",
                            confirmButtonColor: "#000080",
                          });
                        }}
                        href="javascript:void(0);"
                      >
                        {" "}
                        Copy/Paste Link
                      </a>{" "}
                    </label>
                    <InputValidation
                      className=""
                      placeholder="Enter URL"
                      // placeholder="Please Enter Website Address"
                      type="text"
                      id="website"
                      required
                      // disabled={
                      //   props.singlePost.linked || props.updatePage
                      //     ? true
                      //     : false
                      // }
                      name="website"
                      trigger="change"
                      validationError={{
                        isUrl: "This value should be a valid url.",
                      }}
                      value={props.redirectedUrl}
                      onChange={(evt) => {
                        props.callBack(evt);
                      }}
                    />
                  </div>
                ) : null} */}

                <div className="select-categories mt-3">
                  <label>Select Category</label>
                  <Select
                      size="large"
                    key={Date.now()}
                    value={props.category}
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Category"
                    optionFilterProp="children"
                    clearable={false}
                    searchable={false}
                    required
                    onChange={props.changeCategory}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // disabled={
                    //   PermissionHelper.categoryCheck() || props.singleLoading
                    //     ? true
                    //     : false
                    // }
                  >
                    {props.categories.map(({ value, label, parentId }, i) => (
                      <Option value={value}>{label}</Option>
                    ))}
                  </Select>
                </div>

                {/* SubCategory */}

                {props.subCategoriesData?.length > 0 ? (
                  <div className="select-categories mt-3">
                    <label>Select SubCategory</label>

                    <Select
                      size="large"
                      key={Date.now()}
                      value={props.selectSub}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Sub Category"
                      optionFilterProp="children"
                      clearable={false}
                      searchable={false}
                      required
                      onChange={props.changeSubCategory}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    // disabled={
                    //     PermissionHelper.categoryCheck() || props.singleLoading
                    //         ? true
                    //         : false
                    // }
                    >
                      {props.subCategoriesData.map(({ value, label }, i) => (
                        <Option value={value}>{label}</Option>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  {/* <SubCategory
                    subcategoryId={props}
                    categoryData={categoryData}
                  /> */}
                </div>

                <div className="date-range mt-3">
                  <label>Start Date / End Date</label>
                  <RangePicker
                      size="large"
                    key={1}
                    defaultValue={[moment(startDate), moment(endDate)]}
                    value={[moment(startDate), moment(endDate)]}
                    defaultPickerValue={moment(new Date(), "YYYY-MM-DD")}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current < moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                    allowClear={false}
                    ranges={{
                      Today: [moment(), moment()],

                      Tomorrow: [
                        moment().add(1, "days"),
                        moment().add(1, "days"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    style={{ width: "100%" }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                </div>

                {props.singlePost.media_type === "VIDEO" && (

                  <>
                    {source === "ecommerce" ? (
                      <>
                        {connNotFound && (
                          <>
                            {props.category?.length > 0 ?
                              <Button
                                type="button"
                                className="custom_btns_ift mt-2 default-btn fw-normal ml-0 h-40"
                                color="primary"
                                onClick={AddOption}
                              >
                                Add SKU
                              </Button>

                              :
                              <h5 style={{ color: "red" }}><br/>Select Category For Children</h5>
                            }
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {props.category?.length > 0 ?
                          <Button
                            type="button"
                            className="custom_btns_ift mt-2"
                            color="primary"
                            onClick={AddOption}
                          >
                            Add Link
                          </Button>
                          :
                          <h5 style={{ color: "red" }}><br/>Select Category For Children</h5>
                        }
                      </>
                    )}
                  </>
                )}

                {userInfo?.account_type == "influencer" ? (
                  <></>
                ) : (
                  <>
                    {/* <div className="row">
                      <div className="col-md-3 mt-3">
                        <label>PromoCode</label>

                        <Select
                          size="small"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={promoCodePromo ? promoCodePromo : "KB0"}
                          //disabled={!(formState === "add" || formState === "edit")}
                          placeholder="KB0"
                          //loading={this.state.promoCond}
                          optionFilterProp="children"
                          className="w-100"
                          // onSearch={onSearch}
                          onChange={(options, e) => changePromoCode(e, options)}
                          showSearch
                          allowClear={false}
                          loading={promoLoading ? true : false}
                          disabled={source ? true : false}
                        >
                          {promoList.map((customer, key) => {
                            return (
                              <Option key={customer.promo_percent + " " + key}>
                                {customer.promo}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>

                      <div className="col-md-3 mt-3">
                        <label>Discount</label>
                        <div className="promo_discount form-control">
                          {/* {renderConValuePromoList(this.state.promoCodeVal)}
                          {promoCodeDscs ? promoCodeDscs : 0}
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <label>KB Fee</label>
                        <div className="promo_discount form-control">
                          {numeral(Kbfee).format("0,0'")}%
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="row">
                      <div className="col-md-3 mt-3">
                        <label>Amount</label>

                        <InputValidation
                          className=""
                          placeholder="Amount"
                          // placeholder="Please Enter Website Address"
                          type="number"
                          id="website"
                          name="website"
                          trigger="change"
                          disabled={source ? true : false}
                          validations={{
                            matchRegexp: /[0-9]{1}/,
                          }}
                          validationError={{
                            isUrl: "This value should be Number.",
                          }}
                          value={amount}
                          onChange={(e) => changeAmount(e)}
                        />
                      </div>

                      <div className=" col-md-9 mt-3">
                        <label>Description</label>
                        <InputValidation
                          className=""
                          placeholder="Enter Description"
                          // placeholder="Please Enter Website Address"
                          type="text"
                          id="website"
                          name="website"
                          trigger="change"
                          disabled={source ? true : false}
                          value={description}
                          onChange={(e) => changeDescription(e)}
                        />
                      </div>
                    </div> */}
                  </>
                )}

                <div className="edit_button_main pane-button">
                  {props.singlePost.linked || props.updatePage ? (
                    <>
                      {props.loading ? (
                         <Button
                         type="submit"
                         variant="primary"
                         className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                         disabled
                       >
                         <Spinner
                           as="span"
                           animation="border"
                           size="sm"
                           role="status"
                           aria-hidden="true"
                           className="mr-2"
                         />{' '}
                         Loading.....
                       </Button>
                      ) : (
                        <>
                          <Button
                            className="custom_btns_ift default-btn fw-normal ml-0 h-40"
                            color="primary"
                            onClick={(ev) =>
                              props.updatePost(
                                media_id,
                                props.redirectedUrl,
                                promoCodePromo,
                                promoCodeDscs,
                                description,
                                amount,
                                imgDataSet,
                                source,
                                SubCategoryId
                              )
                            }
                          >
                            &nbsp;Update&nbsp;
                          </Button>

                          {/* <Button
                            className="custom_btns_ift"
                            color="primary"
                            onClick={() => props.testUrl(props.redirectedUrl)}
                          >
                            &nbsp;Test&nbsp;
                          </Button> */}

                          <Button
                            className="custom_btns_ift default-btn fw-normal h-40"
                            color="primary"
                            onClick={() => {
                              setUrl("");
                              props.selectPost(false, "");
                              props.closeModel(true);
                            }}
                          >
                            &nbsp;Cancel&nbsp;
                          </Button>

                          <Button
                            className="custom_btns_ift default-btn fw-normal h-40"
                            color="primary"
                            onClick={() => props.deletePost(media_id)}
                          >
                            &nbsp;Remove&nbsp;
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {props.loading ? (
                               <Button
                               type="submit"
                               variant="primary"
                               className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                               disabled
                             >
                               <Spinner
                                 as="span"
                                 animation="border"
                                 size="sm"
                                 role="status"
                                 aria-hidden="true"
                                 className="mr-2"
                               />{' '}
                               Save
                             </Button>
                      ) : (
                        <>
                          <Button
                            className="custom_btns_ift default-btn fw-normal ml-0 h-40"
                            color="primary"
                            onClick={(ev) =>
                            props.savePost &&
                            props.savePost(
                              this,
                              promoCodePromo,
                              promoCodeDscs,
                              description,
                              amount,
                              imgDataSet,
                              source,
                              SubCategoryId,
                              props.singlePost.media_type
                            )
                            }
                          // onClick={(ev) =>
                          //   props.savePost && props.savePost(this)
                          // }
                          >
                            &nbsp;Save&nbsp;
                          </Button>

                          {/* {userInfo?.account_type == "influencer" ? (
                            <Button
                              className="custom_btns_ift"
                              color="primary"
                              onClick={() => "test"}
                            >
                              &nbsp;Test&nbsp;
                            </Button>
                          ) : (
                            <></>
                          )} */}
                          <Button
                            className="custom_btns_ift default-btn fw-normal h-40"
                            color="primary"
                            onClick={() => {
                              props.selectPost(false, "");
                              props.closeModel(false);
                            }}
                          >
                            &nbsp;Cancel&nbsp;
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}

function mapStateToProps({ getPromoRequest, promoRequest }) {
  return { getPromoRequest, promoRequest };
}
export default connect(mapStateToProps, {
  ...promo,
})(ShopRightBar);
