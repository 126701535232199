import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { notification, Spin, Button } from 'antd';
import { accountDelete } from "../../redux/actions/accountDelete";
import {  useDispatch } from "react-redux";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
function AccountDelete() {
const history = useNavigate();
const dispatch = useDispatch();

const [loading, setLoading] = useState(false);

const deleteAccount = async () => {
    Swal.fire({
      title: `Are you sure you want delete your account?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#000080",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        dispatch(accountDelete(userInfo?._id)).then((res) => {
            if (res.payload.success) {
                notification.success({
                    message: "Account Deleted Successfully!",
                    className: "toast-success",
                  });
                  history("/logout");
                  
            } else {
                notification.error({
                    message: res.payload.data.message,
                    className: "toast-error",
                });
            }
        });
      } else {
        setLoading(false)
      }
    });
  };

    return (
      
          <div className="account-deleted">
            <p className="">
              Are You Sure You Want Delete Your Account?
            </p>
            <p className="mb-3">
              This Action Is Not Reversible And It Will Remove All Your Data
              From Our Servers.
            </p>
            <p className="mb-3">
              <i>For Support Please Contact support@ormelive.com.</i>
            </p>
            {loading ? (
              <Button type="primary" danger>
                <Spin />
              </Button>
            ) : (
              <Button  
                onClick={() => {
                  deleteAccount();
                }}
                type="primary"
                danger
              >
                Yes Delete My Account{" "}
              </Button>
            )}
          </div>
    );
}
export default AccountDelete;

